/*
 * Javascript base invokable class
 * Class forms base for invokable parted class js/php
 * 
 * Called methods return a promise for async handling
 */

class Invokable
{
    /**
     * Invokable constructor
     * @returns {undefined}
     */
    constructor()
    {
        /*
         * Private field for invokable data on server side.
         * Chosen construction for inheritance support.
         */
        this._idata = {
            class: null,
            type: 'static'
        };
    }
    /**
     * Method for invoking a post call on the server side of the class
     * @param {object} data
     * @param {function} onload
     * @param {array} args
     * @returns {promise}
     */
    invoke(data,onload,... args)
    {
        if (typeof onload === 'undefined')
        {
            // generate onload function
            // simple resolve promise
            onload = function(response,resolve)
            {
                resolve(response);
            };
        }
        // call on post method
        // supply arguments
        return this.post(data,onload,"/controller/invoke-serverside.php",... args);
    }
    /**
     * Method for invoking a post call on the server side of the class
     * @param {object} data
     * @param {function} onload
     * @param {string} url
     * @param {array} args
     * @returns {promise}
     */
    post(data,onload,url,... args)
    {
        // return new promise
        return new Promise((resolve, reject) => {
            // XHR
            var req = new XMLHttpRequest();
            req.open('POST',url);
            // need to set response type to text to accomodate IE10+
            req.responseType = 'text';
            
            req.onload = () =>
            {
                if (req.status === 200)
                {
                    // parse response to JSON
                    try
                    {
                        let response = JSON.parse(req.response);
                        // check for notices
                        if ('notices' in response)
                        {
                            // handle notices
                            console.log({notices: response.notices});
                        }
                        // verify success of call
                        if (response.success)
                        {
                            // translate html string if supplied
                            if (response.hasOwnProperty('html'))
                            {
                                let template = document.createElement('template');
                                template.innerHTML = response.html.trim();
                                response.element = (typeof template.content !== 'undefined')? template.content.firstChild : template.firstChild;
                            }
                            // call on supplied function
                            onload(response,resolve,reject,... args);
                        }
                        else
                        {
                            // reject the promise
                            console.log(response);
                            reject(Error(response.exception));
                        }
                    }
                    catch(error)
                    {
                        // reject the promise
                        console.log(error);
                        reject(Error(`Failed to parse response: ${req.response}`));
                    }
                }
                else
                {
                    // reject the promise
                    reject(Error(req.statusText));
                }
            };
            
            req.onerror = () =>
            {
                reject(Error("Network Error"));
            };
            
            // issue request
            // assume that data is in a proper format (FormData)
            // append data
            for (let prop in this._idata)
            {
                data.append(prop,this._idata[prop]);
            }
            req.send(data);
        });
    }
}

/*
 * Javascript component class
 * A component is an encapsulated part of the view
 * 
 * All components are added to the view in its constructor
 * Component inherits from Invokable
 * Handling client side of interaction
 */

class Component extends Invokable
{
    /*
     * Component constructor
     * @param {object} context
     * @returns {object instance} 
     */
    constructor(context)
    {
        super();
        if (arguments.length > 0)
        {
            this._context = context;
        }
        // invokable data
        // does not implements its own invokable data
        return this;
    }
}

/*
 * Javascript Lightbox class
 * Used as component in view class
 * 
 * Client side of interaction
 */

class Lightbox extends Component
{
    /*
     * Object constructor
     * @param {object} context
     * @returns {object instance} 
     */
    constructor(context)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Core\\View\\Components\\Lightbox",
            type: 'static'
        };
        // set class default properties
        this._defaults = {
            href: '',
            caption: null,
            order: ()=>{return this._nodes.list.length;},
            group: null
        };
        // maintain a node object
        this._nodes = {
            list: [],
            /*
             * Method for getting the next node, based on _currentNode
             * @returns {node object}
             */
            Next: () => {
                // find node with next higher order
                // maintain reference to node with lowest global order
                let lBound, uBound, nOrder = this._currentNode.properties.order, nGroup = this._currentNode.properties.group;
                for (let cNode, i = 0; i < this._nodes.list.length; i++)
                    (cNode = this._nodes.list[i], cNode.properties.group === nGroup) && 
                        (cNode.properties.order > nOrder ? 
                            ('undefined' == typeof uBound || cNode.properties.order < uBound.properties.order) && (uBound = cNode) : 
                            ('undefined' == typeof lBound || cNode.properties.order < lBound.properties.order) && (lBound = cNode));
                return 'undefined' == typeof uBound ? lBound : uBound;
            },
            /*
             * Method for getting the previous node, based on _currentNode
             * @returns {node object}
             */
            Previous: () => {
                // find node with next lower order
                // maintain reference to node with highest global order
                let lBound, uBound, nOrder = this._currentNode.properties.order, nGroup = this._currentNode.properties.group;
                for (let cNode, i = 0; i < this._nodes.list.length; i++)
                    (cNode = this._nodes.list[i], cNode.properties.group === nGroup) && 
                        (cNode.properties.order < nOrder ? 
                            ('undefined' == typeof lBound || cNode.properties.order > lBound.properties.order) && (lBound = cNode) : 
                            ('undefined' == typeof uBound || cNode.properties.order > uBound.properties.order) && (uBound = cNode));
                return 'undefined' == typeof lBound ? uBound : lBound;
            }
        };
        // keep track of the current node
        this._currentNode = null;
        // keep reference to dom elements
        this._elements = null;
        // maintain a list of listeners
        this._listeners = {
            KeyDown: (e) => {
                // return if composing
                if (e.isComposing || e.keyCode === 229)
                {
                    return;
                }
                // switch on pressed key
                switch (e.keyCode)
                {
                    case 27: // Escape
                        this.Close();
                        break;
                    case 37: // ArrowLeft
                        this.Previous();
                        break;
                    case 39: // ArrowRight
                        this.Next();
                        break;
                }
            }
        };
        // generate elements
        this.GenerateElements();
        /*
         * Mutation callback
         * Removes node from maintained node list
         * Arrow functions is intentional to maintain scope
         * Recursive use inside main function
         * @param {MutationRecords} mlist
         * @returns {undefined} 
         */
        let cb = (mlist)=>{
            /*
             * Inner recursion function
             * @param {NodeList} nlist
             * @returns {undefined}
             */
            let rec = (nlist) => {
                if (typeof nlist === 'undefined' || nlist === null)
                {
                    return;
                }
                // forEach not supported by IE
                for(let ni = 0; ni < nlist.length; ni++)
                {
                    let rstatus = true;
                    for (let i = 0; i < this._nodes.list.length; i++)
                    {
                        if (nlist[ni].isEqualNode(this._nodes.list[i].element))
                        {
                            this._nodes.list.splice(i,1);
                            rstatus = false;
                            break;
                        }
                    }
                    if (rstatus)
                        rec(nlist[ni].childNodes);
                }
            };
            for(var mutation of mlist)
            {
                if (mutation.type == 'childList') {
                    rec(mutation.removedNodes);
                }
            }
        };
        // set a mutation observer with the mutation callback
        this._observer = new MutationObserver(cb);
        // register body with observer for when it's removed
        document.addEventListener('DOMContentLoaded',()=>{
            this._observer.observe(document.body,{childList: true, subtree: true});
        });
        return this;
    }
    /*
     * Lightbox open method
     * Simply opens the lightbox with the loading animation
     * @returns {promise}
     */
    Open()
    {
        // show lightbox element by adding class
        this._elements.container.classList.add('is-visible');
        // show loading animation
        this._elements.loadinganimation.classList.add('is-visible');
        // add keyboard event
        document.addEventListener('keydown',this._listeners.KeyDown);
        return Promise.resolve();
    }
    /*
     * Lightbox close method
     * @returns {promise}
     */
    Close()
    {
        // hide lightbox element by removing class
        this._elements.container.classList.remove('is-visible');
        // remove keyboard events
        document.removeEventListener('keydown',this._listeners.KeyDown);
        return Promise.resolve();
    }
    /*
     * Method for setting an image based on supplied node object
     * @param {node object} node
     * @returns {promise}
     */
    SetImage(node)
    {
        return new Promise((resolve,reject) => {
            // generate image element and append
            let elimage = document.createElement('img');
            elimage.addEventListener('click',(evt) => {
                evt.stopPropagation();
            });
            elimage.setAttribute('data-lightbox-purpose','image');
            this._elements.imagecontainer.appendChild(elimage);
            let img = new Image();
            img.addEventListener('load',() => {
                // hide loading animation
                this._elements.loadinganimation.classList.remove('is-visible');
                // set loaded source
                elimage.src = img.src;
                // set and show caption
                if (node.properties.caption !== null)
                {
                    this._elements.imagecaption.innerHTML = node.properties.caption;
                    this._elements.imagecaption.classList.add('is-visible');
                }
                // set node as current
                this._currentNode = node;
                // resolve promise
                resolve();
            });
            img.addEventListener('error',() => {
                // reject promise
                reject(Error('Image failed to load'));
            });
            img.src = node.properties.href;
        });
    }
    /*
     * Method for clearing the image
     * @returns {promise}
     */
    ClearImage()
    {
        // clear image
        let el = this._elements.imagecontainer.querySelector("[data-lightbox-purpose='image']");
        if (el !== null)
        {
            this._elements.imagecontainer.removeChild(el);
        }
        // clear and hide caption
        this._elements.imagecaption.innerHTML = '';
        this._elements.imagecaption.classList.remove('is-visible');
        // remove current node
        this._currentNode = null;
        return Promise.resolve();
    }
    /*
     * Lightbox next method
     * @returns {promise}
     */
    Next()
    {
        // get next node
        let n = this._nodes.Next();
        if (n === null)
            return Promise.reject(Error('No next image available'));
        // clear image, set next image
        this.ClearImage().then(() => {
            return this.SetImage(n);
        }).catch((error) => {
            console.log(error.stack);
        });
    }
    /*
     * Lightbox previous method
     * @returns {promise}
     */
    Previous()
    {
        // get previous node
        let n = this._nodes.Previous();
        if (n === null)
            return Promise.reject(Error('No previous image available'));
        // clear image, set next image
        this.ClearImage().then(() => {
            return this.SetImage(n);
        }).catch((error) => {
            console.log(error.stack);
        });
    }
    /*
     * Lightbox enable method
     * @param {element} el
     * @param {object} argprop 
     * @returns {promise} 
     */
    Enable(el,argprop = {})
    {
        /*
         * Function for checking if supplied node is set
         * @param {node} argel 
         * @returns {Boolean}
         */
        let nodeSet = (argel) => {
            for (let i = 0; i < this._nodes.list.length; i++)
            {
                if (argel.isEqualNode(this._nodes.list[i].element))
                {
                    return true;
                }
            }
            return false;
        };
        /*
         * Function for setting properties
         * Based on class defaults and provided arguments
         */
        let properties = {};
        ((props) => {
            for (let key in this._defaults)
            {
                if (argprop[key] !== undefined)
                {
                    props[key] = argprop[key];
                } else if (el.hasAttribute(key))
                {
                    props[key] = el.getAttribute(key);
                } else if (el.hasAttribute(`data-${key}`))
                {
                    props[key] = el.getAttribute(`data-${key}`);
                } else
                {
                    props[key] = this._defaults[key];
                }
                if (typeof props[key] == 'function')
                {
                    // pass element as argument
                    props[key] = props[key](el);
                }
            }
        })(properties);
        // register element in object node list
        if (nodeSet(el))
        {
            return Promise.resolve();
        }
        let cobj = {
            element: el,
            properties: properties
        };
        this._nodes.list.push(cobj);
        // add click event listener
        el.addEventListener('click',(evt) => {
            evt.preventDefault();
            // open Lightbox
            this.Open();
            // clear image, then request and set image
            this.ClearImage().then(()=>{
                return this.SetImage(cobj);
            }).catch((error)=>{
                console.log(error.stack);
            });
        });
        return Promise.resolve();
    }
    /*
     * Method for element generation
     * @returns {promise} 
     */
    GenerateElements()
    {
        // generate elements only if null
        if (this._elements !== null)
        {
            return Promise.reject(Error('Elements are already generated'));
        }
        /*
         * Method for mapping elements and setting interaction
         * @param {element} el
         * @returns {object}
         */
        let mapElements = (el) => 
        {
            // define elements
            let robj = {
                container: el,
                imagecontainer: el.querySelector("[data-lightbox-purpose='image-container']"),
                imagecaption: el.querySelector("[data-lightbox-purpose='caption']"),
                loadinganimation: el.querySelector("[data-lightbox-purpose='loading-animation']"),
                navigation: {
                    container: el.querySelector("[data-lightbox-purpose='navigation']"),
                    previous: el.querySelector("[data-lightbox-purpose='action-previous']"),
                    next: el.querySelector("[data-lightbox-purpose='action-next']"),
                    close: el.querySelector("[data-lightbox-purpose='action-close']"),
                }
            };
            // set interactions
            robj.container.addEventListener('click',() => {
                this.Close();
            });
            robj.navigation.container.addEventListener('click',(evt) => {
                evt.stopPropagation();
            });
            robj.navigation.previous.addEventListener('click',() => {
                this.Previous();
            });
            robj.navigation.next.addEventListener('click',() => {
                this.Next();
            });
            robj.navigation.close.addEventListener('click',() => {
                this.Close();
            });
            return robj;
        };
        // call server side for elements
        let data = new FormData();
        data.append("method","GenerateElements");
        this.invoke(data).then((response) => {
            // element is supplied in response
            // insert element in dom as soon as possible
            // we need to wait for the document body to be available
            let fh = ()=>{
                let child = document.body.appendChild(response.element);
                // map elements
                this._elements = mapElements(child);
            };
            (typeof document.body === 'undefined' || document.body === null)? document.addEventListener('DOMContentLoaded',fh):fh();
        }).catch(function(error)
        {
            console.log(error.stack);
        });
        return Promise.resolve();
    }
}

/*
 * Javascript Validator class
 * Used as component in view class
 * 
 * Only client side implementation
 */

class Validator extends Component
{
    /**
     * Object constructor
     * @param {object} context
     * @returns {object instance}  
     */
    constructor(context)
    {
        super(... arguments);
        // maintain a form object
        this._forms = {
            list: new Map(),
            /**
             * Method for adding a form to the list
             * @param {element} form 
             */
            Add: (form) => {
                // add form to list
                // create form element object
                let obj = {
                    elements: {
                        list: form.querySelector('ul[data-validation-purpose="error-list"]')
                    },
                    messages: new Map(),
                    observer: null
                };
                // set form in list
                this._forms.list.set(form, obj);
                /**
                 * Mutation callback
                 * @param {MutationRecords} mlist 
                 */
                let cb = (mlist)=>{
                    /**
                     * Inner recursion function
                     * @param {NodeList} nlist 
                     */
                    let rec = (nlist) => {
                        if (typeof nlist === 'undefined' || nlist === null)
                        {
                            return;
                        }
                        for(let ni = 0; ni < nlist.length; ni++)
                        {
                            if (obj.messages.has(nlist[ni].name))
                            {
                                // clear message
                                this._messages.Clear(form, nlist[ni]);
                                // messages are not nested, break here
                                break;
                            }
                            rec(nlist[ni].childNodes);
                        }
                    };
                    for (var mutation of mlist)
                    {
                        if (mutation.type == 'childList')
                        {
                            rec(mutation.removedNodes);
                        }
                    }
                };
                // set a mutation observer with the mutation callback
                obj.observer = new MutationObserver(cb);
                // register observer with form element
                obj.observer.observe(form,{childList: true, subtree: true});
            }
        };
        // maintain a messages object
        this._messages = {
            /**
             * Method for setting a validation message
             * @param {element} form 
             * @param {element} el 
             * @param {string} message
             */
            Set: (form, el, message) => {
                // get object
                let obj = this._forms.list.get(form);
                if (obj.elements.list === null)
                    return;
                let li, lbl;
                if (obj.messages.has(el.name))
                {
                    // only allow one message per unique name
                    // update message element
                    li = obj.messages.get(el.name);
                    lbl = li.querySelector('label');
                    lbl.childNodes[0].remove();
                    lbl.appendChild(document.createTextNode(message));
                    return;
                }
                // add message element
                li = document.createElement('li');
                li.setAttribute('data-validation-for',el.id);
                lbl = document.createElement('label');
                lbl.setAttribute('for',el.id);
                lbl.appendChild(document.createTextNode(message));
                li.appendChild(lbl);
                obj.elements.list.appendChild(li);
                // store message element
                obj.messages.set(el.name, li);
            },
            /**
             * Method for getting a validation message
             * @param {element} el 
             * @returns {boolean}
             */
            Get: (el) => {
                let map = new Map([
                    ['valueMissing','required'],
                    ['typeMismatch','type'],
                    ['patternMismatch','pattern'],
                    ['tooLong','maxlength'],
                    ['tooShort','minlength'],
                    ['rangeUnderflow','min'],
                    ['rangeOverflow','max'],
                    ['stepMismatch','step']]);
                let val = el.validity;
                for (let [key,value] of map)
                {
                    if (val[key] === true)
                    {
                        // look for set message
                        let aname = 'data-validation-message-' + value;
                        if (el.hasAttribute(aname))
                            return el.getAttribute(aname);
                    }
                }
                let aname = 'data-validation-message';
                if (el.hasAttribute(aname))
                    return el.getAttribute(aname);
                return false;
            },
            /**
             * Method for clearing a validation message
             * @param {element} form 
             * @param {element} el 
             */
            Clear: (form, el) => {
                // get object
                let obj = this._forms.list.get(form);
                if (obj.elements.list === null)
                    return;
                if (!obj.messages.has(el.name))
                    // message should be set but just to make sure
                    return;
                // remove message element
                let li = obj.messages.get(el.name);
                li.remove();
                obj.messages.delete(el.name);
            },
            /**
             * 
             * @param {element} form 
             * @param {element} el 
             * @param {string} message 
             * @returns 
             */
            Process: (form, el, message) => {
                // set id on element if empty
                if (el.id.length == 0)
                {
                    el.id = 'el-'+Date.now();
                }
                if (message === false)
                {
                    // message is valid
                    el.classList.remove('validation-invalid');
                    // clear message
                    this._messages.Clear(form, el);
                    return;
                }
                // message is invalid
                // add class for invalid state here
                // invalid selector (:invalid) is visible without user interaction
                el.classList.add('validation-invalid');
                // set message
                this._messages.Set(form, el, message);
            }
        };
        // maintain an elements object
        this._elements = {
            /**
             * 
             * @param {element} form 
             * @param {element} el 
             * @returns {boolean}
             */
            Validate: (form, el) => {
                if (el.willValidate === undefined)
                {
                    return true;
                }
                if (el.validity.valid)
                {
                    // el is valid
                    this._messages.Process(form,el,false);
                    return true;
                }
                // el is invalid
                // get suitable message
                let message = this._messages.Get(el);
                // process message
                this._messages.Process(form,el,message);
                return false;
            }
        };
        return this;
    }
    /**
     * Validator enable method
     * @param {element} form
     * @param {submit function} cb
     * @returns {undefined}
     */
    Enable(form,cb = false)
    {
        // add form to object list
        this._forms.Add(form);
        // set attribute on form
        form.setAttribute('novalidate', true);
        // add event listeners
        form.addEventListener('blur',(event)=>{
            // validate target
            this._elements.Validate(form, event.target);
        },true);
        form.addEventListener('submit',(event)=>{
            // validate all elements
            let targets = event.target.elements;
            let valid = true;
            for(let i = 0; i < targets.length; i++)
            {
                let response = this._elements.Validate(form, targets[i]);
                if (response !== true && valid)
                {
                    valid = false;
                    targets[i].focus();
                }
            }
            if (valid)
            {
                if (cb === false)
                {
                    // submit form
                    return;
                }
                cb();
            }
            event.preventDefault();
        });
    }
}

/**
 * Javascript Logger class
 * Used as component in view class
 * 
 * Client side of interaction
 */

class Logger extends Component
{
    constructor(context)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Core\\View\\Components\\Logger",
            type: 'static'
        };
        // elements object
        this._elements = {
            list: [],
            container: null,
            /**
             * Method for adding an element
             * @param {element to add} element 
             * @returns {added element}
             */
            Add: (element) => {
                // set element in list
                this._elements.list.push(element);
                // add element as child to container
                let child = this._elements.container.appendChild(element);
                // set style on event element to allow for transition
                // embed in timeout
                let event = child.querySelector("[data-logger-purpose='event']");
                window.setTimeout(()=>{
                    event.style.maxHeight = '300px';
                },10);
                return child;
            },
            /**
             * Method for removing an element
             * @param {element to remove} element 
             * @returns {removed element}
             */
            Remove: (element) => {
                // remove element from list
                for (let i = 0; i < this._elements.list.length; i++)
                {
                    if (element.isEqualNode(this._elements.list[i]))
                    {
                        this._elements.list.splice(i,1);
                        break;
                    }
                }
                // remove element from DOM
                return this._elements.container.removeChild(element);
            }
        };
        // maintain an event handler object
        // handlers are supplied as onload functions in invoke call
        this._handlers = {
            Log: (response,resolve) => {
                console.log(response);
                resolve();
            },
            Inform: (response,resolve) => {
                // add element to elements object
                let el = this._elements.Add(response.element);
                // show element
                el.show();
                // remove element after timeout
                window.setTimeout(()=>{
                    this._elements.Remove(el);
                },2000);
                resolve();
            },
            Confirm: (response,resolve,reject) => {
                // add element to elements object
                let el = this._elements.Add(response.element);
                // set confirm to close and resolve
                el.querySelector("button[data-action='ok']").addEventListener('click',(e)=>{
                    e.preventDefault();
                    this._elements.Remove(el);
                    resolve();
                });
                // set cancel to close and reject
                el.querySelector("button[data-action='cancel']").addEventListener('click',(e)=>{
                    e.preventDefault();
                    this._elements.Remove(el);
                    reject(Error('Action was canceled by user'));
                });
                // show as modal
                el.showModal();
            },
            Error: (response,resolve) => {
                // add element to elements object
                let el = this._elements.Add(response.element);
                let thandle; // timeout handle
                // add mouseover listener
                el.addEventListener('mouseover',()=>{
                    window.clearTimeout(thandle);
                });
                // add mouseout listener
                el.addEventListener('mouseout',()=>{
                    thandle = window.setTimeout(()=>{
                        this._elements.Remove(el);
                    },1000);
                });
                // show element
                el.show();
                // remove element after timeout
                thandle = window.setTimeout(()=>{
                    this._elements.Remove(el);
                },2000);
                resolve();
            }
        };
        // generate element container
        this.GenerateElementContainer();
        return this;
    }
    /**
     * Messaging log method
     * @param {string} message 
     * @returns {promise}
     */
    Log(message)
    {
        // create data array
        let data = new FormData();
        data.append("method","Log");
        data.append("message",message);

        // issue call to server, returns promise
        return this.invoke(data,this._handlers.Log);
    }
    /**
     * Messaging inform method
     * @param {string} message 
     * @returns {promise}
     */
    Inform(message)
    {
        // create data array
        let data = new FormData();
        data.append("method","Inform");
        data.append("message",message);

        // issue call to server, returns promise
        return this.invoke(data,this._handlers.Inform);
    }
    /**
     * Messaging confirm method
     * @param {string} message 
     * @returns {promise}
     */
    Confirm(message)
    {
        // create data array
        let data = new FormData();
        data.append("method","Confirm");
        data.append("message",message);

        // issue call to server, returns promise
        return this.invoke(data,this._handlers.Confirm);
    }
    /**
     * Messaging error method
     * @param {string} message 
     * @returns {promise}
     */
    Error(message)
    {
        // create data array
        let data = new FormData();
        data.append("method","Error");
        data.append("message",message);

        // issue call to server, returns promise
        return this.invoke(data,this._handlers.Error);
    }
    /**
     * Method for element container generation
     * @returns {promise}
     */
    GenerateElementContainer()
    {
        // generate element container only if null
        if (this._elements.container !== null)
        {
            return Promise.reject(Error('Element container is already generated'));
        }
        // call server side for elements
        let data = new FormData();
        data.append("method","GenerateElementContainer");
        this.invoke(data).then((response) => {
            // element is supplied in response
            // insert element in dom as soon as possible
            // we need to wait for the document body to be available
            let fh = ()=>{
                let child = document.body.appendChild(response.element);
                this._elements.container = child;
            };
            (typeof document.body === 'undefined' || document.body === null)? document.addEventListener('DOMContentLoaded',fh):fh();
        }).catch(function(error)
        {
            console.log(error.stack);
        });
    }
}

/**
 * Javascript access manager class
 * Used as component in view class
 * 
 * Client side of interaction
 */

class AccessManager extends Component
{
    /**
     * Object constructor
     * @param {object} context 
     * @returns {object}
     */
    constructor(context)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Core\\View\\Components\\AccessManager",
            type: 'static'
        };
        // maintain a conditions object
        this._conditions = {
            list: [],
            container: null,
            currentIndex: 0,
            /**
             * Method for challenging a user for conditions
             * @returns {Promise}
             */
            Challenge: () => {
                return new Promise((resolve, reject)=>{
                    // immediate resolve if list is empty
                    if (this._conditions.list.length < 1)
                        resolve({success: true});
                    // process each condition in sequence
                    // start with the first condition
                    this._conditions.processCondition(
                        this._conditions.getCurrentCondition(), resolve, reject);
                });
            },
            /**
             * Method for getting the current, at the index, condition
             * @returns {object}
             */
            getCurrentCondition: () => {
                return this._conditions.list[this._conditions.currentIndex];
            },
            /**
             * Method for processing a supplied condition
             * @param {object} condition 
             * @param {function} resolve 
             * @param {function} reject 
             */
            processCondition: (condition, resolve, reject) => {
                // create element from condition html
                let template = document.createElement('template');
                template.innerHTML = condition.html.trim();
                let element = template.content.firstChild;
                // insert element in container
                let child = this._conditions.container.appendChild(element);
                // child is dialog element
                // set style on dialog element to allow for transition, embed in timeout
                window.setTimeout(()=>{
                    child.style.maxHeight = '100vh';
                },10);
                // set event listener for cancel (by keypress and button)
                let fh_cancel = (e)=>{
                    e.preventDefault();
                    child.remove();
                    this._conditions.Clear();
                    reject(Error('Access verification challenge was canceled by user'));
                };
                child.addEventListener('cancel',fh_cancel);
                child.querySelector("button[data-action='cancel']").addEventListener('click',fh_cancel);
                // set validator for form and submit function
                let form = child.querySelector("form[data-access-manager-purpose='challenge-form']");
                this._context.View.components.Validator.Enable(form,()=>{
                    // pass form data for verification
                    // side effect Promise
                    let data = new FormData(form);
                    data.append('method','ValidateChallenge');
                    this.invoke(data,(response,innerresolve,innerreject)=>{
                        // response status contains validation result
                        if (response.status)
                        {
                            // remove child
                            child.remove();
                            // resolve side effect Promise
                            innerresolve();
                            // check if this condition is the last one
                            if (this._conditions.list.length == this._conditions.currentIndex +1)
                            {
                                this._conditions.Clear();
                                resolve({success: true});
                                return;
                            }
                            // process next condition in line
                            this._conditions.currentIndex++;
                            this._conditions.processCondition(
                                this._conditions.getCurrentCondition(), resolve, reject);
                            return;
                        }
                        // reject side effect Promise
                        innerreject(Error('Access verification challenge failed'));
                    }).catch((error)=>{
                        console.log(error);
                        form.reset();
                        return this._context.View.Shake(form);
                    });
                });
                // show as modal
                child.showModal();
            },
            /**
             * Method for setting conditions in object
             * @param {array} conditions 
             */
            Set: (conditions) => {
                this._conditions.list = conditions;
            },
            /**
             * Method for clearing conditions in object
             */
            Clear: () => {
                this._conditions.list = [];
                this._conditions.currentIndex = 0;
            }
        };
        // generate element container
        this.GenerateElementContainer();
        return this;
    }
    /**
     * Method for access verification by tag
     * @param {string} tag 
     * @returns {Promise}
     */
    VerifyAccess(tag)
    {
        // call server side to verify access
        let data = new FormData();
        data.append("tag",tag);
        data.append("method","VerifyAccess");
        return this.invoke(data).then((response) => {
            // get conditions
            // filter on status
            let conditions = response.access.filter((condition)=>!condition.status);
            // set conditions in object
            this._conditions.Set(conditions);
            // challenge conditions
            return this._conditions.Challenge();
        });
    }
    /**
     * Method for conditions container generation
     * @returns {Promise}
     */
    GenerateElementContainer()
    {
        // generate conditions container only if null
        if (this._conditions.container !== null)
        {
            return Promise.reject(Error('Element container is already generated'));
        }
        // call server side for elements
        let data = new FormData();
        data.append("method","GenerateElementContainer");
        this.invoke(data).then((response) => {
            // element is supplied in response
            // insert element in dom as soon as possible
            // we need to wait for the document body to be available
            let fh = ()=>{
                let child = document.body.appendChild(response.element);
                this._conditions.container = child;
            };
            (typeof document.body === 'undefined' || document.body === null)? document.addEventListener('DOMContentLoaded',fh):fh();
        }).catch(function(error)
        {
            console.log(error.stack);
        });
    }
}

/**
 * Javascript navigator class
 * Used as component in view class
 * 
 * Client side of interaction
 */

class Navigator extends Component
{
    /**
     * Navigator constructor
     * @param {object} context
     * @returns {object instance} 
     */
    constructor(context)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Core\\View\\Components\\Navigator",
            type: 'static'
        };
        // maintain an object for navigator events
        this._events = {
            // timeout handle for resize event
            resizehandle: null,
            listeners: {
                /**
                 * Listener for hash change
                 */
                hashchange: ()=>{
                    this.ScrollToAnchor();
                },
                /**
                 * Listener for resize
                 */
                resize: ()=>{
                    // use timer and handle to further reduce load
                    let fh = ()=>{
                        if (!this._navigation.isLoaded)
                            return false;
                        // set breakpoint position
                        this._navigation.breakpoint.SetPosition();
                        // set container height
                        this._navigation.container.SetHeight();
                        // set navigation position
                        this._navigation.SetPosition();
                    };
                    if (this._events.resizehandle !== null)
                    {
                        window.clearTimeout(this._events.resizehandle);
                    }
                    this._events.resizehandle = window.setTimeout(fh, 500);
                },
                /**
                 * Listener for scroll
                 */
                scroll: ()=>{
                    // set navigation position
                    this._navigation.SetPosition();
                },
                // custom listeners
                custom: new Map([
                    ["ToggleNavigation",[]]
                ])
            },
            /**
             * Method for registering events
             * @returns {Promise}
             */
            Register: ()=>{
                // add listener for hash change
                window.addEventListener('hashchange', this._events.listeners.hashchange);
                // add listener for window resize
                // passive, to hinder throttling
                window.addEventListener('resize', this._events.listeners.resize,
                    {capture: false, passive: true});
                // add listener for scroll
                // passive, to hinder throttling
                document.addEventListener('scroll', this._events.listeners.scroll,
                    {capture: false, passive: true});
                return Promise.resolve();
            }
        };
        // maintain an object for the registered object
        this._navigation = {
            // boolean for loaded status
            isLoaded: false,
            // handle for navigation timeout calls
            timeoutHandle: null,
            // duration for navigation timeout calls
            timeoutDuration: 700,
            // container object
            container: {
                el: null,
                height: -1,
                /**
                 * Set container height
                 */
                SetHeight: ()=>{
                    this._navigation.container.height = this._navigation.container.el.offsetHeight;
                },
                /**
                 * Listener for mouse leave
                 */
                mouseleave: (e)=>{
                    // handle list
                    if (this._navigation.timeoutHandle !== null)
                    {
                        window.clearTimeout(this._navigation.timeoutHandle);
                    }
                    this._navigation.elements.ClearTimeouts();
                    this._navigation.timeoutHandle = window.setTimeout(this._navigation.elements.UpdateList,
                        this._navigation.timeoutDuration, 0, 0);
                }
            },
            // breakpoint object
            breakpoint: {
                el: null,
                position: -1,
                /**
                 * Set breakpoint position
                 */
                SetPosition: ()=>{
                    this._navigation.breakpoint.position = this._navigation.breakpoint.el.offsetTop;
                }
            },
            // elements object
            elements: {
                // list of elements
                list: new Map(),
                /**
                 * Method for adding an element to the list
                 * @param {element} el 
                 */
                Add: (el)=>{
                },
                /**
                 * Method for clearing timeouts on objects in list
                 */
                ClearTimeouts: ()=>{
                    for (const k of this._navigation.elements.list.keys())
                    {
                        // get object
                        let obj = this._navigation.elements.list.get(k);
                        // clear timeout
                        window.clearTimeout(obj.handle);
                        // remove class to indicate loading
                        obj.element.classList.remove('navigation-expanding');
                    }
                },
                /**
                 * Method for updating the list items
                 * @param {*} key 
                 * @param {*} level 
                 */
                UpdateList: (key, level)=>
                {
                    // clear handle
                    this._navigation.timeoutHandle = null;
                    // check all items in list
                    for (const k of this._navigation.elements.list.keys())
                    {
                        // get object
                        let obj = this._navigation.elements.list.get(k);
                        // check for closing item
                        // items with higher level should be closed
                        // items with same level and different key should be closed
                        // items with lower level should be left open
                        if (obj.level > level || (obj.level == level && obj.key != key))
                        {
                            // hide visually
                            this._navigation.elements.ToggleNavigation(obj.element, obj.container, 'remove');
                            // clear timeout
                            window.clearTimeout(obj.handle);
                            // remove class to indicate loading
                            obj.element.classList.remove('navigation-expanding');
                            // remove from list
                            this._navigation.elements.list.delete(k);
                        }
                    }
                },
                /**
                 * Function for hiding/showing navigation
                 * Fname is a function name into classList (add/remove)
                 * @param {element} element 
                 * @param {element} container 
                 * @param {string} fname 
                 */
                ToggleNavigation: (element, container, fname)=>{
                    // get level from container
                    let level = container.dataset.navigationLevel;
                    // handle current active navigation for same level
                    let el = this._navigation.container.el.querySelector(`[data-navigation-container][data-navigation-active][data-navigation-level='${level}']`);
                    if (el !== null)
                    {
                        el.classList[fname]('is-hidden');
                    }
                    // show/hide navigation for specified element
                    container.classList[fname]('is-visible');
                    element.classList[fname]('navigation-expanded');
                    // call all registered functions in custom listener
                    this._events.listeners.custom.get('ToggleNavigation').forEach((handler)=>{
                        handler();
                    });
                },
                /**
                 * Listener for mouse enter
                 */
                mouseenter: (e)=>{
                    // get element (target) from event
                    let element = e.target;
                    // get navigation key and level from element
                    let key = element.dataset.navigationKey;
                    let level = element.dataset.navigationLevel;
                    // clear handles if set
                    if (this._navigation.timeoutHandle !== null)
                    {
                        window.clearTimeout(this._navigation.timeoutHandle);
                    }
                    this._navigation.elements.ClearTimeouts();
                    // call update method in timeout
                    this._navigation.timeoutHandle = window.setTimeout(this._navigation.elements.UpdateList,
                        this._navigation.timeoutDuration, key, level);
                    // find container matching key
                    // don't match active container
                    let container = document.querySelector(`li:not([data-navigation-active])[data-navigation-container][data-navigation-parent='${key}']`);
                    if (container === null)
                        return;
                    // start timer for showing navigation
                    let handle = window.setTimeout(this._navigation.elements.ToggleNavigation, 
                        this._navigation.timeoutDuration, element, container, 'add');
                    // set class to indicate loading
                    element.classList.add('navigation-expanding');
                    // add to navigation list
                    // use key in array
                    let obj = {
                        key,
                        element,
                        container,
                        level,
                        handle
                    };
                    this._navigation.elements.list.set(key, obj);
                }
            },
            /**
             * Load method for navigation
             * DOM content is always available on call
             * @returns {Promise}
             */
            Load: ()=>{
                return new Promise((resolve)=>{
                    // set breakpoint position and container height
                    // embed in function to await stabilization
                    let fh = () =>
                    {
                        // get current breakpoint position
                        let pval = this._navigation.breakpoint.position;
                        // update breakpoint position
                        this._navigation.breakpoint.SetPosition();
                        // set container height
                        this._navigation.container.SetHeight();
                        // check for another round
                        let cval = this._navigation.breakpoint.position;
                        if (cval == 0 || pval !== cval)
                        {
                            window.setTimeout(fh,100);
                        }
                        else
                        {
                            // check if document is loaded
                            if (document.readyState !== 'complete')
                            {
                                // add extra call at load
                                document.addEventListener('load',()=>{
                                    // update breakpoint position
                                    this._navigation.breakpoint.SetPosition();
                                });
                            }
                            // set as loaded
                            this._navigation.isLoaded = true;
                            // resolve promise
                            resolve();
                        }
                    };
                    fh();
                });
            },
            /**
             * Method for setting navigation position
             * @returns {Promise}
             */
            SetPosition: ()=>{
                // check if window is past breakpoint position
                if (window.scrollY > this._navigation.breakpoint.position)
                {
                    // set navigation as sticky and breakpoint height
                    this._navigation.container.el.classList.add('sticky');
                    let ch = this._navigation.container.height;
                    this._navigation.breakpoint.el.setAttribute('style',`height: ${ch}px;`);
                }
                else if(this._navigation.container.el.classList.contains('sticky'))
                {
                    // remove navigation as sticky
                    this._navigation.container.el.classList.remove('sticky');
                    this._navigation.breakpoint.el.setAttribute('style','');
                }
                return Promise.resolve();
            }
        };
        // locate navigation element (if present)
        {
            // need to wait for the document body to be available
            let fh = ()=>{
                let el = document.querySelector("[data-navigation-purpose='container']");
                if (el !== null)
                {
                    // register element
                    this.Register(el);
                }
            };
            (typeof document.body === 'undefined' || document.body === null)? document.addEventListener('DOMContentLoaded',fh):fh();
        }        return this;
    }
    /**
     * Method for registration of element as navigation object
     * Register method is always called after DOM content is loaded
     * @param {element} element 
     * @returns {Promise}
     */
    Register(element)
    {
        // set element in object
        this._navigation.container.el = element;
        // set listeners for navigation container
        this._navigation.container.el.addEventListener('mouseleave', this._navigation.container.mouseleave);
        // get navigation breakpoint element
        let breakpoint = this.GetBreakpointElement();
        // insert navigation breakpoint element before navigation element
        this._navigation.container.el.before(breakpoint);
        // store breakpoint element
        this._navigation.breakpoint.el = breakpoint;
        // get all navigation elements
        let elements = element.querySelectorAll("[data-navigation-element]");
        elements.forEach((el)=>{
            // set listener for element
            el.addEventListener('mouseenter', this._navigation.elements.mouseenter);
        });
        // call load method
        return this._navigation.Load().then(()=>{
            // position navigation
            return this._navigation.SetPosition();
        }).then(()=>{
            // register event listeners
            return this._events.Register();
        });
    }
    /**
     * Method for getting the breakpoint element
     * This method creates the element client side to avoid server call
     * @returns {element}
     */
    GetBreakpointElement()
    {
        // create element
        let element = document.createElement('section');
        // set class and attribute
        element.classList.add('ct-navigation-breakpoint');
        element.setAttribute('data-navigator-purpose','breakpoint');
        // return element
        return element;
    }
    /**
     * Method for scrolling to anchor
     * @returns {undefined}
     */
    ScrollToAnchor()
    {
        // get anchor from href
        let index = location.href.indexOf('#');
        if (index < 0 || !this._navigation.isLoaded)
            return false;
        // get anchor element
        let element = document.querySelector(location.hash);
        // get navigation container height
        let height = this._navigation.container.height;
        // scroll into view, account for navigation height
        window.scrollTo(window.scrollX, element.offsetTop - height);
    }
    /**
     * Method for adding event listener to component
     * @param {string} name 
     * @param {function} listener 
     */
    addEventListener(name, listener)
    {
        // add listener to custom listeners if name matches key
        if (!this._events.listeners.custom.has(name))
        {
            throw new Error(`Event listener '${name}' has no handling.`);
        }
        this._events.listeners.custom.get(name).push(listener);
    }
}

/**
 * Javascript block class
 * A block is a part of the user interface which can be requested separately
 * 
 * Handling client side of interaction
 */

class Block extends Invokable
{
    /**
     * Block constructor
     * @param {object} context 
     * @returns {object instance}  
     */
    constructor(context)
    {
        super();
        if (arguments.length > 0)
        {
            this._context = context;
        }
        // invokable data
        // does not implement its own invokable data
        /**
         * Activation object with state and callback properties
         * The activation state indicates a step in the document load process
         */
        this.activation = {
            state: 'interactive',
            callback: null
        };
        /**
         * Properties object with list methods
         */
        this.properties = {
            // style properties
            styles: {
                filter: null,
                /**
                 * Method for parsing customization properties
                 * @returns {Array}
                 */
                Parse: () => {
                    /**
                     * Internal function for determining if style sheet is on same domain
                     * @param {CSSStyleSheet} styleSheet 
                     * @returns 
                     */
                    const isSameDomain = (styleSheet) => {
                        if (!styleSheet.href) {
                        return true;
                        }
                    
                        return styleSheet.href.indexOf(window.location.origin) === 0;
                    };
                    // early return if no filter is set
                    if (this.properties.styles.filter === null)
                        return [];

                    return [...document.styleSheets].filter(isSameDomain).reduce((scarry, sheet) =>
                        scarry.concat([... sheet.cssRules]).filter(r=>r.cssText.includes('ct-widget-condensed')).filter((el)=>{
                            return el.type==1||(el.type==4&&window.matchMedia(el.conditionText).matches);
                        }).reduce((rcarry, rule) =>{
                            return rcarry.concat(rule.type==1?rule:[...rule.cssRules])
                        },[]).reduce((pcarry, rule) => {
                            const props = [... rule.style].filter(propName => propName.indexOf("--") === 0).
                                map((propName) => [
                                    propName.replace('--','').trim(),
                                    rule.style.getPropertyValue(propName).trim()]);
                            return [... pcarry, ...props];
                        },[])
                    ,[]);
                }
            },
            list: [],
            /**
             * Method for getting a value from the list
             * @param {string} prop 
             * @returns {*}
             */
            Get: (prop)=>{
                return this.properties.list[prop];
            },
            /**
             * Method for setting a value in the list
             * @param {string} prop
             * @param {*} value
             * @returns {undefined}
             */
            Set: (prop,value)=> {
                this.properties.list[prop] = value;
            },
            /**
             * Method for checking if a property is set
             * @param {string} prop 
             * @returns {boolean}
             */
            IsSet: (prop)=> {
                return prop in this.properties.list;
            },
            /**
             * Method for converting properties to a FormData object
             * @returns {FormData}
             */
            ToFormData: ()=> {
                // create data array
                let data = new FormData();
                // append standard properties
                for (let prop in this.properties.list)
                {
                    data.append(prop,this.properties.list[prop]);
                }
                // append style properties
                // style properties are only reliable if block is set to load at 'complete' state
                if (this.activation.state == 'complete')
                {
                    let sprop = this.properties.styles.Parse();
                    sprop.forEach((prop)=>{
                        let pname = prop[0].replace(/-([a-z])/gi, ($0,$1)=>{return $1.toUpperCase();});
                        data.append(pname,prop[1]);
                    });
                }
                return data;
            }
        };
        return this;
    }

    /**
     * Method for getting a full form (element string) element
     * @param {element} el 
     * @returns {promise}
     */
    GetElement(el)
    {
        // get property data array
        let data = this.properties.ToFormData();
        data.append("method","GetElement");
        
        // issue call to server, returns promise
        // include activation callback if set
        return (this.activation.callback === null)? 
            this.invoke(data):this.invoke(data,this.activation.callback);
    }
}

/**
 * Javascript widget class
 * A widget is specialized contents added dynamically to the view
 * The widget class is built on the block class, with added functionality
 * 
 * All widgets are loaded asynchronously
 * Handling client side of interaction
 */

class Widget extends Block
{
    /**
     * Widget constructor
     * @param {object} context
     * @param {element} el
     * @returns {object instance} 
     */
    constructor(context,el)
    {
        super(... arguments);
        if (arguments.length > 0)
        {
            this._context = context;
        }
        // invokable data
        // does not implements its own invokable data
        // populate properties
        if (arguments.length > 1 && el.hasAttributes() && 
            (el.classList.contains('ct-widget-condensed') || el.classList.contains('ct-widget-parsed')))
        {
            let attr = el.attributes;
            for (let i = 0; i < attr.length; i++)
            {
                let prop = attr[i].name.replace('data-','');
                if (prop === 'class')
                    continue;
                // convert to correct type
                let val = attr[i].value;
                if (typeof val == "string")
                {
                    if (!isNaN(val))
                    {
                        // numeric conversion
                        val = +val;
                    }
                    else if (val == "true" || val == "false")
                    {
                        // boolean conversion
                        val = val == "true";
                    }
                }
                this.properties.Set(prop,val);
            }
        }
        // edit action property, used to control insertion
        this._iseditaction = false;
        return this;
    }
    /**
     * Method for getting a condensed form element
     * @returns {promise} 
     */
    GetCondensedElement()
    {
        // create data array
        let data = new FormData();
        data.append("method","GetCondensedElement");
        for (let prop in this.properties.list)
        {
            data.append(prop,this.properties.list[prop]);
        }
        
        // issue call to server, returns promise
        return this.invoke(data);
    }
    /**
     * Method for setting edit action on the widget
     * @param {boolean} value 
     */
    SetEditAction(value)
    {
        this._iseditaction = value;
    }
    /**
     * Method for generic plugin submit
     * @param {TinyMCE editor} editor 
     * @returns {undefined} 
     */
    PluginSubmit(editor)
    {
        /**
         * Function for removing MCE Bogus line break elements
         * @param {element} parent 
         */
        let fh_removeMCEBogus = (parent)=>{
            let el = parent.querySelector(":scope > br[data-mce-bogus='1']");
            if (el === null)
                return;
            el.remove();
        };
        // disable editing for body
        editor.getBody().setAttribute('contenteditable', false);
        // call to get element, side effect
        this.GetCondensedElement().then((data)=>{
            // get element from data
            let el = data.element;
            // set focus
            editor.focus();
            // check if element is valid inside selection
            let sel = editor.selection.getNode();
            // remove bogus line breaks if set
            fh_removeMCEBogus(sel);
            if (this._iseditaction)
            {
                // submit is edit action, replace selected element
                sel.replaceWith(el);
            }
            else if (editor.schema.isValidChild(sel.tagName,el.tagName))
            {
                // insert as child in selection
                sel.appendChild(el);
            }
            else if (sel.childNodes.length > 1 || (sel.childNodes.length == 1 && sel.childNodes[0].tagName != 'BR'))
            {
                // insert as sibling after selection
                let sibling = sel.nextElementSibling;
                if (sibling === null)
                {
                    // append to parent element
                    sel.parentElement.appendChild(el);
                }
                else
                {
                    // insert before sibling
                    sel.parentElement.insertBefore(el,sibling);
                }
            }
            else
            {
                // replace selected (empty) element
                sel.replaceWith(el);
            }
            // set click listener
            el.addEventListener('click',()=>{
                editor.selection.select(el);
            });
            // select element
            editor.selection.select(el);
            // disable editing for element
            el.setAttribute('contenteditable', false);
            // enable editing for body
            editor.getBody().setAttribute('contenteditable', true);
        }).catch((error)=>{
            console.log(error);
        });
    }
}

/*
 * Javascript single image widget class
 * 
 * Handling client side of interaction
 */

class SingleImage extends Widget
{
    /*
     * View constructor
     * @param {object} context
     * @param {element} el
     * @returns {object instance} 
     */
    constructor(context,el)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Core\\View\\Widgets\\SingleImage",
            type: 'static'
        };
        // properties
        // set activation state to complete to delay after css
        // this is required for proper width estimate
        this.activation.state = 'complete';
        // set activation callback
        this.activation.callback = (response,resolve,reject,order)=>{
            // set lightbox functionality if enabled
            // pass supplied order
            if (this.properties.list.islightboxenabled)
            {
                let elc = response.element.querySelector('a');
                context.View.components.Lightbox.Enable(elc,
                {
                    'order':order
                });
            }
            resolve(response);
        };
        return this;
    }
    
    /**
     * Method for getting a full form element
     * This method overrides the (block) parent method
     * @param {element} el (element to replace)
     * @param {int} order (element order in document)
     * @returns {promise}
     */
    GetElement(el,order)
    {
        // add width of element to replace and screen width to properties
        this.properties.Set('elementwidth',el.offsetWidth);
        this.properties.Set('screenwidth',window.innerWidth);

        // get property data array
        let data = this.properties.ToFormData();
        data.append("method","GetElement");
        
        // issue call to server, returns promise
        return this.invoke(data,this.activation.callback,order);
    }
    
    /*
     * Method for getting the plugin object
     * The plugin object is prepared for use in TinyMCE
     * @returns {object}
     */
    GetPluginObject()
    {
        /**
         * Method for generating image buttons
         * @param {album object} album 
         * @param {selected image key} imagekey
         * @returns {image button object}
         */
        let generateImageButtons = (album,imagekey = 0) => {
            // generate object array for image values
            let cval = [];
            album.Images.forEach((el)=>{
                // add image as icon
                let iname = `widget-singleImage-${el.pK}`;
                tinymce.activeEditor.ui.registry.addIcon(iname,`<img src="${el.Path}${el.Dimensions[0].Suffix}${el.Extension}" alt="${el.pK}">`);
                // create button for image
                cval.push({
                    type: 'button',
                    text: el.pK,
                    icon: iname,
                    name: el.pK,
                    buttonType: el.pK == imagekey?'primary':'secondary'
                });
            });
            return cval;
        };
        // return object
        let robj;
        // editor object
        let editor;
        // create album selectbox base
        let aalbumsselectboxvalues = [];
        // create image button base
        let aimagebuttons = [];
        /**
         * Dialog configuration object
         * Get initial data from properties list, ensure string format
         */
        let dConfiguration = {
            title: 'Single image',
            body : {
                type: 'panel',
                items: [{
                    type: 'label',
                    label: 'This widget inserts a single image at the caret position.',
                    items: []
                },{
                    type: 'selectbox',
                    name: 'albumkey',
                    label: 'Select album',
                    items: aalbumsselectboxvalues
                },{
                    type: 'checkbox',
                    name: 'islightboxenabled',
                    label: 'Enable lightbox'
                },{
                    type: 'checkbox',
                    name: 'showasthumbnail',
                    label: 'Show as square thumbnail'
                },{
                    type: 'checkbox',
                    name: 'showdescription',
                    label: 'Show description as figure caption'
                },{
                    type: 'bar',
                    items: aimagebuttons // there is a focus bug related to having many images, place this last to soften the blow
                }]
            },
            initialData: {
                islightboxenabled: this.properties.IsSet('islightboxenabled')?this.properties.Get('islightboxenabled'):false,
                showasthumbnail: this.properties.IsSet('showasthumbnail')?this.properties.Get('showasthumbnail'):false,
                showdescription: this.properties.IsSet('showdescription')?this.properties.Get('showdescription'):false
            },
            buttons: [{
                type: 'submit',
                text: 'Insert'
            },{
                type: 'cancel',
                text: 'Cancel'
            }],
            onAction: (api,details) => {
                // store selected button value
                let name = details.name;
                this.properties.Set('imagekey',name);
                // set selected button as primary
                aimagebuttons.forEach((el)=>{
                    el.buttonType = (el.name == name)?'primary':'secondary';
                });
                dConfiguration.body.items[5].items = aimagebuttons;
                // redial dialog
                api.redial(dConfiguration);
            },
            onChange: (api,details) => {
                // set enable status for checkboxes
                let name = details.name;
                let data = api.getData();
                let value = data[name];
                switch (name) {
                    case 'albumkey':
                        // get current selected album
                        let i;
                        for (i = 0; i < aalbums.length; i++)
                        {
                            if (aalbums[i].pK === value)
                                break;
                        }
                        // generate object array for image values
                        // assign object array for image values
                        dConfiguration.body.items[5].items = aimagebuttons = generateImageButtons(aalbums[i]);
                        // set current album selection as initial data and store property
                        dConfiguration.initialData.albumkey = value;
                        this.properties.Set('albumkey',value);
                        // redial dialog
                        api.redial(dConfiguration);
                        break;
                    case 'islightboxenabled':
                    case 'showasthumbnail':
                    case 'showdescription':
                        dConfiguration.initialData[name] = value;
                        this.properties.Set(name,value);
                        break;
                }
            },
            onSubmit: (api)=>{
                this.PluginSubmit(editor);
                api.close();
            }
        };
        // issue call to verify existance of module
        // if successful, issue call to get albums
        // call is a side effect to the main call
        // main call must finish in sync
        let aalbums, data = new FormData();
        data.append('module','Core\\Modules\\Images');
        let promise = this._context.Controller.SystemHasModule(data).then((data)=>{
            if (data.available === true)
            {
                return this._context.Controller.ImagesGetAlbums().then((data)=>{
                    aalbums = data['albums'];
                    let selectedalbum;
                    // append values to album selectbox
                    let kset = false;
                    for (let ai = 0; ai < aalbums.length; ai++)
                    {
                        aalbumsselectboxvalues.push({
                            text: `${aalbums[ai]['pK']}: ${aalbums[ai]['Name']}`,
                            value: aalbums[ai]['pK']
                        });
                        // check if key matches property value (if set)
                        if (this.properties.IsSet('albumkey') && this.properties.Get('albumkey') == aalbums[ai]['pK'])
                        {
                            dConfiguration.initialData['albumkey'] = aalbums[ai]['pK'];
                            kset = true;
                            selectedalbum = aalbums[ai];
                        }
                    }
                    // initialize image selection for first album
                    if (aalbums.length > 0)
                    {
                        // handle albumkey if not set
                        if (!kset)
                        {
                            // set first album as initial data and store property
                            dConfiguration.initialData.albumkey = aalbums[0].pK;
                            this.properties.Set('albumkey',aalbums[0].pK);
                        }
                        // set image key in initial data if set in properties
                        if (this.properties.IsSet('imagekey'))
                        {
                            let imagekey = this.properties.Get('imagekey');
                            // ensure string format
                            dConfiguration.initialData.imagekey = `${imagekey}`;
                            // generate object array for image values
                            aimagebuttons = generateImageButtons(selectedalbum,imagekey);
                        }
                        else
                        {
                            // generate object array for image values
                            // default handling of buttons
                            aimagebuttons = generateImageButtons(aalbums[0]);
                        }
                        // assign object array for image values
                        dConfiguration.body.items[5].items = aimagebuttons;
                        // set plugin as enabled (in robj)
                        robj.disabled = false;
                    }
                }).catch((error)=>{
                    console.log(error);
                });
            }
        });
        /**
         * Plugin interaction
         * Only modern browsers
         * @returns {undefined}
         */
        let fhclick = ()=>{
            editor = tinymce.activeEditor;
            // open window
            editor.windowManager.open(dConfiguration);
        };
        /**
         * Return object
         * @type object
         */
        robj = {
            text: 'Single Image',
            icon: 'image',
            disabled: true,
            onAction: fhclick,
            resolved: promise
        };
        return robj;
    }
}

/*
 * Javascript calendar extract widget class
 * 
 * Handling client side of interaction
 */

class CalendarExtract extends Widget
{
    /**
     * View constructor
     * @param {object} context
     * @param {element} el
     * @returns {object instance}  
     */
    constructor(context,el)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Core\\View\\Widgets\\CalendarExtract",
            type: 'static'
        };
        return this;
    }
    
    /**
     * Method for getting the plugin object
     * The plugin object is prepared for use in TinyMCE
     * Only modern browsers
     * @returns {object}
     */
    GetPluginObject()
    {
        // return object
        let robj;
        // editor object
        let editor;
        // create calendar selectbox base
        let acalendarselectboxvalues = [];
        // create duration selectbox
        let adurationselectboxvalues = [{
            text: 'One week',
            label: 'One week',
            value: 'P1W'
        },{
            text: 'One month',
            label: 'One month',
            value: 'P1M'
        },{
            text: 'One year',
            label: 'One year',
            value: 'P1Y'
        }];
        /**
         * Dialog configuration object
         * Get initial data from properties list
         */
        let dConfiguration = {
            title: 'Calendar extract',
            body: {
                type: 'panel',
                items: [{
                    type: 'label',
                    label: 'This widget inserts a calendar extract at the caret position.',
                    items: []
                },{
                    type: 'selectbox',
                    name: 'calendarkey',
                    label: 'Select calendar',
                    items: acalendarselectboxvalues
                },{
                    type: 'selectbox',
                    name: 'duration',
                    label: 'Select extract duration',
                    items: adurationselectboxvalues
                },{
                    type: 'input',
                    name: 'eventlimit',
                    label: 'Select event limit'
                },{
                    type: 'label',
                    label: 'Set event limit to 0 for unlimited number of events.',
                    items: []
                },{
                    type: 'checkbox',
                    name: 'hideendtime',
                    label: 'Hide end time for events'
                }]
            },
            initialData: {
                duration: this.properties.IsSet('duration')?this.properties.Get('duration'):adurationselectboxvalues[0].value,
                eventlimit: this.properties.IsSet('eventlimit')?`${this.properties.Get('eventlimit')}`:'0',
                hideendtime: this.properties.IsSet('hideendtime')?this.properties.Get('hideendtime'):false,
            },
            buttons: [{
                type: 'submit',
                text: 'Insert'
            },{
                type: 'cancel',
                text: 'Cancel'
            }],
            onChange: (api,details) => {
                // store current value in properties
                let name = details.name;
                let data = api.getData();
                let value = data[name];
                this.properties.Set(name,value);
            },
            onSubmit: (api)=>{
                this.PluginSubmit(editor);
                api.close();
            }
        };
        // issue call to verify existance of module
        // if successful, issue call to get calendars
        // call is a side effect to the main call
        // main call must finish in sync
        let acalendars, data = new FormData();
        data.append('module','Core\\Modules\\Calendars');
        let promise = this._context.Controller.SystemHasModule(data).then((data)=>{
            if (data.available === true)
            {
                return this._context.Controller.CalendarsGetCalendars().then((data)=>{
                    acalendars = data['calendars'];
                    // append values to calendar selectbox
                    acalendars.forEach((item)=>{
                        acalendarselectboxvalues.push({
                            text: `${item['pK']}: ${item['Name']}`,
                            value: item['pK']
                        });
                        // check if key matches property value (if set)
                        if (this.properties.IsSet('calendarkey') && this.properties.Get('calendarkey') == item['pK'])
                        {
                            dConfiguration.initialData['calendarkey'] = item['pK'];
                        }
                    });
                    if (acalendars.length > 0)
                    {
                        // store initial values in object properties, if not set
                        if (!this.properties.IsSet('calendarkey'))
                        {
                            this.properties.Set('calendarkey',acalendars[0].pK);
                        }
                        if (!this.properties.IsSet('duration'))
                        {
                            this.properties.Set('duration',adurationselectboxvalues[0].value);
                        }
                        // set plugin as enabled (in robj)
                        robj.disabled = false;
                    }
                }).catch((error)=>{
                    console.log(error);
                });
            }
        });
        /*
         * Plugin interaction
         * @returns {undefined}
         */
        let fhclick = ()=>{
            editor = tinymce.activeEditor;
            // open window
            editor.windowManager.open(dConfiguration);
        };
        /*
         * Return object
         * @type object
         */
        robj = {
            text: 'Calendar extract',
            icon: 'calendar-alt',
            disabled: true,
            onAction: fhclick,
            resolved: promise
        };
        return robj;
    }
}

/**
 * Javascript cloud storage folder widget class
 * 
 * Handling client side of interaction
 */

class CloudStorageFolder extends Widget
{
    /**
     * View constructor
     * @param {object} context
     * @param {element} el
     * @returns {object instance}  
     */
    constructor(context,el)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Core\\View\\Widgets\\CloudStorageFolder",
            type: 'static'
        };
        // set activation callback
        this.activation.callback = (response,resolve) => {
            // enable button functionality for all files
            // get embedded form
            let felement = response.element.querySelector("form[data-storage-purpose='download-form']");
            let ifield = felement.querySelector("input[data-storage-purpose='identification-field']");
            let files = response.element.querySelectorAll('article.file');
            for (let i = 0; i < files.length; i++)
            {
                // get button
                let belement = files[i].querySelector("button[data-storage-purpose='download-file']");
                // check if file has a content link
                if (files[i].hasAttribute('data-contentlink'))
                {
                    // send user to specified content link
                    belement.addEventListener('click',()=>{
                        window.location.href = files[i].getAttribute('data-contentlink');
                    });
                }
                else
                {
                    // download file through form action
                    belement.addEventListener('click',()=>{
                        // set form action from button
                        felement.action = belement.getAttribute('data-action');
                        // set value in identification element
                        ifield.value = files[i].getAttribute('data-identification');
                        // submit form
                        felement.submit();
                    });
                }
            }
            resolve(response);
        };
        return this;
    }
    
    /**
     * Method for getting the plugin object
     * The plugin object is prepared for use in TinyMCE
     * Only modern browsers
     * @returns {object}
     */
    GetPluginObject()
    {
        // return object
        let robj;
        // editor object
        let editor;
        // create storage selectbox base
        let astorageselectboxvalues = [];
        /**
         * Dialog configuration object
         */
        let dConfiguration = {
            title: 'Cloud storage folder',
            body : {
                type: 'panel',
                items: [{
                    type: 'label',
                    label: 'This widget inserts a file list for a folder at the caret position',
                    items: []
                },{
                    type: 'selectbox',
                    name: 'storagekey',
                    label: 'Select cloud storage',
                    items: astorageselectboxvalues
                },{
                    type: 'input',
                    name: 'identification',
                    label: 'Folder identification'
                }
            ]
            },
            initialData: {
                identification: this.properties.IsSet('identification')?this.properties.Get('identification'):'',
                storagekey: 0 // set below
            },
            buttons: [{
                type: 'submit',
                text: 'Insert'
            },{
                type: 'cancel',
                text: 'Cancel'
            }],
            onChange: (api,details) => {
                let name = details.name;
                let data = api.getData();
                let value = data[name];
                this.properties.Set(name,value);
            },
            onSubmit: (api)=>{
                this.PluginSubmit(editor);
                api.close();
            }
        };
        // issue call to verify existance of module
        // if successful, issue call to get modules
        // call is a side effect to the main call
        // main call must finish in sync
        let astorages, data = new FormData();
        data.append('module','Core\\Modules\\Storages');
        let promise = this._context.Controller.SystemHasModule(data).then((data)=>{
            if (data.available === true)
            {
                return this._context.Controller.StoragesGetStorages().then((data)=>{
                    astorages = data['storages'];
                    // append values to storages selectbox
                    let kset = false;
                    for (let ai = 0; ai < astorages.length; ai++)
                    {
                        astorageselectboxvalues.push({
                            text: `${astorages[ai]['pK']}: ${astorages[ai]['Name']}`,
                            value: astorages[ai]['pK']
                        });
                        // check if key matches property value (if set)
                        if (this.properties.IsSet('storagekey') && this.properties.Get('storagekey') == astorages[ai]['pK'])
                        {
                            kset = true;
                            dConfiguration.initialData['storagekey'] = astorages[ai]['pK'];
                        }
                    }
                    // handle storagekey if not set
                    if (!kset && astorages.length > 0)
                    {
                        this.properties.Set('storagekey',astorages[0].pK);
                        dConfiguration.initialData.storagekey = astorages[0].pK;
                    }
                    if (astorages.length > 0)
                    {
                        // set plugin as enabled (in robj)
                        robj.disabled = false;
                    }
                }).catch((error)=>{
                    console.log(error);
                });
            }
        });
        /**
         * Plugin interaction
         * Only modern browsers
         * @returns {undefined}
         */
        let fhclick = ()=>{
            editor = tinymce.activeEditor;
            // open window
            editor.windowManager.open(dConfiguration);
        };
        /**
         * Return object
         * @type object
         */
        robj = {
            text: 'Cloud storage folder',
            icon: 'cloud',
            disabled: true,
            onAction: fhclick,
            resolved: promise
        };
        return robj;
    }
}

/**
 * Javascript news articles widget class
 * 
 * Handling client side of interaction
 */

 class NewsArticles extends Widget
 {
     /**
      * View constructor
      * @param {object} context 
      * @param {element} el 
      * @returns {object instance}
      */
     constructor(context,el)
     {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Core\\View\\Widgets\\NewsArticles",
            type: 'static'
        };
        // properties
        // set activation state to complete to delay after css
        // this is required for proper width estimate
        this.activation.state = 'complete';
        // set activation callback
        this.activation.callback = (response,resolve) => {
            // parse all widgets
            context.View.ParseWidgetsInParent(response.element);
            // handle any instagram carousel album posts
            this.HandleInstagramPosts(response.element);
            // resolve callback
            resolve(response);
        };
        return this;
     }
     /**
      * Method for handling any instagram posts in container
      * @param {element} container 
      */
     HandleInstagramPosts(container)
     {
        let elements = container.querySelectorAll("figure[data-instagram-media-type='carousel_album']");
        if (elements !== null)
        {
            elements.forEach((el)=>{
                // get carousel elements
                // get container for carousel
                let ccontainer = el.querySelector("[data-purpose='carousel-container']");
                // get viewport for scroll elements
                let cviewport = el.querySelector("[data-purpose='carousel-viewport']");
                // handler for window resize
                {
                    // function for resize event
                    let thandle = null;
                    // resize handles both image height and viewport scroll
                    let rcb = ()=>{
                        thandle = null;
                        // reset viewport scroll
                        cviewport.scrollLeft = 0;
                        // update container height based on images
                        let images = ccontainer.querySelectorAll('img');
                        let imageHeight = 0;
                        images.forEach((image)=>{
                            imageHeight = Math.max(imageHeight,image.offsetHeight);
                        });
                        ccontainer.style.height = `${imageHeight}px`;
                    };
                    // outer handler function for resize event, used to avoid throttling
                    let rcbouter = ()=>{
                        // clearTimeout silently does nothing if handle does not exist
                        window.clearTimeout(thandle);
                        thandle = window.setTimeout(rcb,200);
                    };
                    window.addEventListener('resize',rcbouter);
                }                // handler for image height
                {
                    if (ccontainer !== null)
                    {
                        // object for observer
                        let observer;
                        // create function for checking image height
                        // use counter to fail with grace
                        let icbcount = 0;
                        let icb = (images)=>{
                            // increase counter
                            icbcount++;
                            if (icbcount > 10)
                            {
                                // fail with grace
                                // remove observer
                                observer.disconnect();
                                return;
                            }
                            // get image height
                            // height can at this moment be 0, if so postpone by setTimeout
                            let imageHeight = 0;
                            images.forEach((image)=>{
                                imageHeight = Math.max(imageHeight,image.offsetHeight);
                            });
                            if (imageHeight == 0)
                            {
                                window.setTimeout(icb,200,images);
                                return;
                            }
                            // set height for carousel container
                            ccontainer.style.height = `${imageHeight}px`;
                            ccontainer.style.paddingTop = 'initial';
                            // remove observer
                            observer.disconnect();
                            return;
                        };
                        // use mutation observer to trigger when inserted
                        // create mutation callback
                        let mcallback = (mlist)=>{
                            // check if carousel container is a descendant of the added node
                            let nmatch = false;
                            mlist.forEach((mrecord)=>{
                                if (mrecord.addedNodes.length>0)
                                {
                                    mrecord.addedNodes.forEach((node)=>{
                                        nmatch = nmatch || node.contains(ccontainer);
                                    });
                                }
                            });
                            if (nmatch)
                            {
                                // carousel is inserted
                                // call on height handler
                                let images = ccontainer.querySelectorAll('img');
                                icb(images);
                            }
                        };
                        observer = new MutationObserver(mcallback);
                        observer.observe(document.body,{childList: true, subtree: true});
                    }
                }                // handler for viewport scroll
                {
                    if (cviewport !== null)
                    {
                        // get navigation elements, represented as anchors
                        let anchors = el.querySelectorAll('a');
                        anchors.forEach((anchor)=>{
                            anchor.addEventListener('click',(e)=>{
                                // get identification of scroll target
                                let identification = anchor.getAttribute('data-target');
                                // get element for identification
                                let el = document.getElementById(identification);
                                if (el !== null)
                                {
                                    // get container elemenent coordinates
                                    let r0 = cviewport.getBoundingClientRect();
                                    // get element coordinates
                                    let r1 = el.getBoundingClientRect();
                                    // scroll container
                                    cviewport.scrollLeft += r1.x - r0.x;
                                    // prevent default (fallback)
                                    e.preventDefault();
                                }
                            });
                        });
                    }
                }            });
        }
     }
    
     /**
      * Method for getting a full form element
      * This method overrides the (block) parent method
      * @param {element} el (element to replace)
      * @param {int} order (element order in document)
      * @returns {promise}
      */
     GetElement(el,order)
     {
         // add width of element to replace and screen width to properties
         this.properties.Set('elementwidth',el.offsetWidth);
 
         // get property data array
         let data = this.properties.ToFormData();
         data.append("method","GetElement");
         
         // issue call to server, returns promise
         return this.invoke(data,this.activation.callback,order);
     }

     /**
      * Method for getting the plugin object
      * The plugin object is prepared for use in TinyMCE
      * Only modern browsers
      * @returns {object}
      */
     GetPluginObject()
     {
        // return object
        let robj;
        // editor object
        let editor;
        // create provider selectbox base
        let aprovidersselectboxvalues = [];
        /**
         * Dialog configuration object
         * Get initial data from properties list, ensure string format
         */
        let dConfiguration = {
            title: 'News articles',
            body: {
                type: 'panel',
                items: [{
                    type: 'label',
                    label: 'This widget inserts a list of news articles at the caret position',
                    items: []
                },{
                    type: 'selectbox',
                    name: 'providerkey',
                    label: 'Select news provider',
                    items: aprovidersselectboxvalues
                },{
                    type: 'input',
                    name: 'articlecount',
                    label: 'Article count'
                },{
                    type: 'input',
                    name: 'articleoffset',
                    label: 'Article offset'
                }]
            },
            initialData: {
                articlecount : this.properties.IsSet('articlecount')?`${this.properties.Get('articlecount')}`:'3',
                articleoffset: this.properties.IsSet('articleoffset')?`${this.properties.Get('articleoffset')}`:'0'
            },
            buttons: [{
                type: 'submit',
                text: 'Insert'
            },{
                type: 'cancel',
                text: 'Cancel'
            }],
            onChange: (api,details) => {
                // store current value in properties
                let name = details.name;
                let data = api.getData();
                let value = data[name];
                this.properties.Set(name,value);
            },
            onSubmit: (api)=>{
                this.PluginSubmit(editor);
                api.close();
            }
        };
        // issue call to verify existance of module
        // if successful, set widget as enabled
        // call is a side effect to the main call
        // main call must finish in sync
        let aproviders, data = new FormData();
        data.append('module','Core\\Modules\\News');
        let promise = this._context.Controller.SystemHasModule(data).then((data)=>{
            if (data.available === true)
            {
                return this._context.Controller.NewsGetProviders().then((data)=>{
                    aproviders = data['providers'];
                    // append values to providers selectbox
                    aproviders.forEach((item)=>{
                        aprovidersselectboxvalues.push({
                            text: `${item['pK']}: ${item['Name']}`,
                            value: item['pK']
                        });
                        // check if key matches property value (if set)
                        if (this.properties.IsSet('providerkey') && this.properties.Get('providerkey') == item['pK'])
                        {
                            dConfiguration.initialData['providerkey'] = item['pK'];
                        }
                    });
                    if (aproviders.length > 0)
                    {
                        // store initial values in object properties, if not set
                        if (!this.properties.IsSet('providerkey'))
                        {
                            this.properties.Set('providerkey',aproviders[0].pK);
                        }
                        // set plugin as enabled (in robj)
                        robj.disabled = false;
                    }
                }).catch((error)=>{
                    console.log(error);
                });
            }
        });
        /**
         * Plugin interaction
         * Only modern browsers
         * @returns {undefined}
         */
        let fhclick = ()=>{
            editor = tinymce.activeEditor;
            // open window
            editor.windowManager.open(dConfiguration);
        };
        /**
         * Return object
         * @type object
         */
        robj = {
            text: 'News articles',
            icon: 'newspaper',
            disabled: true,
            onAction: fhclick,
            resolved: promise
        };
        return robj;
     }
 }

/**
 * Javascript form widget class
 * 
 * Handling client side of interaction
 */

class Form extends Widget
{
    /**
     * Widget constructor
     * @param {object} context 
     * @param {element} el 
     * @returns {object instance}
     */
    constructor(context,el)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Core\\View\\Widgets\\Form",
            type: 'static'
        };
        this._element = null;
        // set activation callback
        this.activation.callback = (response,resolve,reject) => {
            // store response element
            this._element = response.element;
            // get form element
            let felement = response.element.querySelector("form");
            if (felement === null)
            {
                return reject();
            }
            // enable validator for form
            // handle loading state for button
            // get button
            let belement = felement.querySelector("button[type='submit']");
            context.View.components.Validator.Enable(felement,()=>{
                // handle loading state for button
                // explicit width required for transition effect
                let r = belement.getBoundingClientRect();
                belement.style.width = r.width + 'px';
                // set disabled and active
                belement.disabled = true;
                belement.dataset.active = '';
                // add form response through controller
                let msg, el = this._element;
                context.Controller.FormsAddResponse(felement).then((data)=>{
                    felement.reset();
                    if (felement.hasAttribute('data-message-inform-success'))
                    {
                        msg = felement.getAttribute('data-message-inform-success');
                        return context.View.Inform(msg);
                    }
                    return Promise.resolve();
                }).then(()=>{
                    // need to replace form with fresh copy as elements may change in submit process
                    return this.GetCondensedElement().then((rdata)=>{
                        // this call stores new element in object
                        return this.GetElement(rdata.element);
                    }).then((rdata)=>{
                        // replace element
                        el.parentNode.replaceChild(rdata.element,el);
                        return Promise.resolve();
                    });
                }).catch((error)=>{
                    console.log(error.stack);
                    if (felement.hasAttribute('data-message-inform-failure'))
                    {
                        msg = felement.getAttribute('data-message-inform-failure');
                        return context.View.Error(msg);
                    }
                    return Promise.resolve();
                }).finally(()=>{
                    // handle loading state for button
                    // remove disabled and active
                    belement.disabled = false;
                    delete belement.dataset.active;
                });
            });
            // call all recaptcha scripts in response (only one per form)
            let script = response.element.querySelector("script[data-purpose='google-recaptcha-script']");
            if (script !== null)
            {
                // get container id for recaptcha element (only first)
                let container_id = response.element.querySelector("[data-purpose='google-recaptcha-element']").id;
                // get unique identification for script tag
                let identification = script.getAttribute('data-identification');
                // create recaptcha loading function in window
                // the function will only be used when recaptcha has loaded
                window[`recaptcha${identification}`] = ()=>
                {
                    // get container
                    let container = document.getElementById(container_id);
                    // render recaptcha
                    grecaptcha.render(container,{
                        'sitekey':container.getAttribute('data-sitekey'),
                        'size':'normal',
                        'theme':'light'
                    });
                    // transfer validator properties to rendered textarea
                    let tarea = container.querySelector("[name='g-recaptcha-response']");
                    if (tarea !== null)
                    {
                        tarea.setAttribute('data-validation-message', container.getAttribute('data-validation-message'));
                        if (container.hasAttribute('data-required'))
                        {
                            tarea.setAttribute('required','');
                        }
                    }
                };
                // cloning does not work, do it the long way
                let el = document.createElement('script');
                // transfer attributes
                // add loading function to src
                el.src = `${script.src}&onload=recaptcha${identification}`;
                el.defer = script.defer;
                // insert script element in element
                this._element.appendChild(el);
                script.remove();
            }
            resolve(response);
        };
        return this;
    }

    /**
     * Method for getting the plugin object
     * The plugin object is prepared for use in TinyMCE
     * @returns {object}
     */
    GetPluginObject()
    {
        let robj, editor;
        // create form selectbox base
        let aformsselectboxvalues = [];
        /**
         * Dialog configuration object
         */
        let dConfiguration = {
            title: 'Form',
            body: {
                type: 'panel',
                items: [{
                    type: 'label',
                    label: 'This widget inserts a form at the caret position.',
                    items: []
                },{
                    type: 'selectbox',
                    name: 'tag',
                    label: 'Select form',
                    items: aformsselectboxvalues
                }]
            },
            initialData: {},
            buttons: [{
                type: 'submit',
                text: 'Insert'
            },{
                type: 'cancel',
                text: 'Cancel'
            }],
            onChange: (api,details) => {
                // get current selected form and tag
                let name = details.name;
                let data = api.getData();
                let value = data[name];
                this.properties.Set('tag',value);
            },
            onSubmit: (api)=>{
                this.PluginSubmit(editor);
                api.close();
            }
        };
        // issue call to verify existance of module
        // if successful, issue call to get forms for current editor language
        let aforms, data = new FormData();
        data.append('module','Core\\Modules\\Forms');
        let promise = this._context.Controller.SystemHasModule(data).then((data)=>{
            if (data.available === true)
            {
                // interact with view component to get context language
                let lcode = this._context.View.components.TinyMCE.language.context;
                data = new FormData();
                data.append('lcode',lcode);
                return this._context.Controller.FormsGetFormsByLanguage(data).then((data)=>{
                    aforms = data['forms'];
                    // append values to form selectbox
                    for (let fi = 0; fi < aforms.length; fi++)
                    {
                        aformsselectboxvalues.push({
                            text: aforms[fi]['Title'],
                            value: aforms[fi]['Tag']
                        });
                        // check if tag matches property value (if set)
                        if (this.properties.IsSet('tag') && this.properties.Get('tag') === aforms[fi]['Tag'])
                        {
                            dConfiguration.initialData['tag'] = aforms[fi]['Tag'];
                        }
                    }
                    if (aforms.length > 0)
                    {
                        // store initial values in object properties, if not set
                        if (!this.properties.IsSet('tag'))
                        {
                            this.properties.Set('tag',aformsselectboxvalues[0].value);
                        }
                        // set plugin as enabled (in robj)
                        robj.disabled = false;
                    }
                }).catch((error)=>{
                    console.log(error);
                });
            }
        });
        /**
         * Plugin interaction
         * Only modern browsers
         * @returns {undefined}
         */
        let fhclick = ()=>{
            editor = tinymce.activeEditor;
            // open window
            editor.windowManager.open(dConfiguration);
        };
        /**
         * Return object
         * @type object
         */
        robj = {
            text: 'Form',
            icon: 'file-alt',
            disabled: true,
            onAction: fhclick,
            resolved: promise
        };
        return robj;
    }
}

/**
 * Javascript album gallery widget class
 * 
 * Handling client side of interaction
 */

 class AlbumGallery extends Widget
 {
    /*
     * View constructor
     * @param {object} context
     * @param {element} el
     * @returns {object instance} 
     */
    constructor(context,el)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Core\\View\\Widgets\\AlbumGallery",
            type: 'static'
        };
        // properties
        // set activation state to complete to delay after css
        // this is required for proper width estimate
        this.activation.state = 'complete';
        // set activation callback
        this.activation.callback = (response,resolve,reject,order)=>{
            // set lightbox functionality if enabled
            // pass supplied order
            if (this.properties.list.islightboxenabled)
            {
                let elc = response.element.querySelectorAll('a');
                for (let i=0; i<elc.length; i++)
                {
                    context.View.components.Lightbox.Enable(elc[i],
                    {
                        'order':order+i/elc.length
                    });
                }
            }
            resolve(response);
        };
        return this;
    }
    
    /**
     * Method for getting a full form element
     * This method overrides the (block) parent method
     * @param {element} el (element to replace)
     * @param {int} order (element order in document)
     * @returns {promise}
     */
    GetElement(el,order)
    {
        // add width of element to replace and screen width to properties
        this.properties.Set('elementwidth',el.offsetWidth);
        this.properties.Set('screenwidth',window.innerWidth);

        // get property data array
        let data = this.properties.ToFormData();
        data.append("method","GetElement");
        
        // issue call to server, returns promise
        return this.invoke(data,this.activation.callback,order);
    }

    /**
     * Method for getting the plugin object
     * The plugin object is prepared for use in TinyMCE
     * @returns {object}
     */
    GetPluginObject()
    {
        // return object
        let robj;
        // editor object
        let editor;
        // create album selectbox base
        let aalbumsselectboxvalues = [];
        // create margin selectbox values
        let amarginselectboxvalues = [];
        for (let i=0;i<=10;i++)
        {
            amarginselectboxvalues.push({
                text: `${i} px`,
                value: `${i}`
            });
        }
        /**
         * Dialog configuration object
         * Get initial data from properties list, ensure string format
         */
        let dConfiguration = {
            title: 'Album gallery',
            body: {
                type: 'panel',
                items: [{
                    type: 'label',
                    label: 'This widget inserts an album gallery at the caret position.',
                    items: []
                },{
                    type: 'selectbox',
                    name: 'albumkey',
                    label: 'Select album',
                    items: aalbumsselectboxvalues
                },{
                    type: 'selectbox',
                    name: 'imagemargin',
                    label: 'Image margin',
                    items: amarginselectboxvalues
                },{
                    type: 'checkbox',
                    name: 'islightboxenabled',
                    label: 'Enable lightbox'
                },{
                    type: 'checkbox',
                    name: 'showassquares',
                    label: 'Show as square thumbnails'
                },{
                    type: 'checkbox',
                    name: 'showdescription',
                    label: 'Show description as figure caption'
                }]
            },
            initialData: {
                islightboxenabled: this.properties.IsSet('islightboxenabled')?this.properties.Get('islightboxenabled'):false,
                imagemargin: this.properties.IsSet('imagemargin')?`${this.properties.Get('imagemargin')}`:amarginselectboxvalues[0].value,
                showassquares: this.properties.IsSet('showassquares')?this.properties.Get('showassquares'):true,
                showdescription: this.properties.IsSet('showdescription')?this.properties.Get('showdescription'):false
            },
            buttons: [{
                type: 'submit',
                text: 'Insert'
            },{
                type: 'cancel',
                text: 'Cancel'
            }],
            onChange: (api,details)=> {
                // called from selectbox and checkbox
                // same handling for all
                let name = details.name;
                let data = api.getData();
                let value = data[name];
                this.properties.Set(name,value);
            },
            onSubmit: (api)=>{
                this.PluginSubmit(editor);
                api.close();
            }
        };
        // issue call to verify existance of module
        // if successful, issue call to get albums
        // call is a side effect to the main call
        // main call must finish in sync
        let aalbums, data = new FormData();
        data.append('module','Core\\Modules\\Images');
        let promise = this._context.Controller.SystemHasModule(data).then((data)=>{
            if (data.available === true)
            {
                return this._context.Controller.ImagesGetAlbums().then((data)=>{
                    aalbums = data['albums'];
                    // append values to album selectbox
                    // append values to album selectbox
                    for (let ai = 0; ai < aalbums.length; ai++)
                    {
                        aalbumsselectboxvalues.push({
                            text: `${aalbums[ai]['pK']}: ${aalbums[ai]['Name']}`,
                            value: aalbums[ai]['pK']
                        });
                        // check if key matches property value (if set)
                        if (this.properties.IsSet('albumkey') && this.properties.Get('albumkey') == aalbums[ai]['pK'])
                        {
                            dConfiguration.initialData['albumkey'] = aalbums[ai]['pK'];
                        }
                    }
                    if (aalbums.length > 0)
                    {
                        // store initial values in object properties, if not set
                        if (!this.properties.IsSet('albumkey'))
                        {
                            this.properties.Set('albumkey',aalbums[0].pK);
                        }
                        if (!this.properties.IsSet('imagemargin'))
                        {
                            this.properties.Set('imagemargin',amarginselectboxvalues[0].value);
                        }
                        // set plugin as enabled (in robj)
                        robj.disabled = false;
                    }
                }).catch((error)=>{
                    console.log(error);
                })
            }
        });
        /**
         * Plugin interaction
         * Only modern browsers
         * @returns {undefined}
         */
        let fhclick = ()=>{
            editor = tinymce.activeEditor;
            // open window
            editor.windowManager.open(dConfiguration);
        };
        /**
         * Return object
         * @type object
         */
        robj = {
            text: 'Album gallery',
            icon: 'images',
            disabled: true,
            onAction: fhclick,
            resolved: promise
        };
        return robj;
    }
 }

/**
 * Javascript Facebook page (oembed) widget class
 * 
 * Handling client side of interaction
 */

class FacebookPage extends Widget
{
    /**
     * View constructor
     * @param {object} context 
     * @param {element} el 
     * @returns {object instance}
     */
    constructor(context,el)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Core\\View\\Widgets\\FacebookPage",
            type: 'static'
        };
        // set activation callback
        this.activation.callback = (response,resolve) => {
            // call all scripts in response
            let scripts = response.element.querySelectorAll('script');
            scripts.forEach((script)=>{
                // create new script element
                // cloning does not seem to work, do it the long way
                let el = document.createElement('script');
                // transfer only the source attribute
                el.src = script.src;
                // insert script element in document
                document.documentElement.appendChild(el);
                // remove script
                script.remove();
            });
            resolve(response);
        };
        return this;
    }
    /**
     * Method for getting the plugin object
     * The plugin object is prepared for use in TinyMCE
     * Only modern browsers
     * @returns {object}
     */
    GetPluginObject()
    {
        // return object
        let robj;
        // editor object
        let editor;
        /**
         * Dialog configuration object
         * Get initial data from properties list, ensure string format
         */
        let dConfiguration = {
            title: 'Facebook page',
            body: {
                type: 'panel',
                items: [{
                    type: 'label',
                    label: 'This widget inserts an embedded Facebook page at the caret position',
                    items: []
                },{
                    type: 'input',
                    name: 'url',
                    label: 'Page url'
                },{
                    type: 'checkbox',
                    name: 'hidecover',
                    label: 'Hide cover image'
                },{
                    type: 'checkbox',
                    name: 'usesmallheader',
                    label: 'Use small header'
                }]
            },
            initialData: {
                url: this.properties.IsSet('url')?this.properties.Get('url'):'',
                hidecover: this.properties.IsSet('hidecover')?this.properties.Get('hidecover'):false,
                usesmallheader: this.properties.IsSet('usesmallheader')?this.properties.Get('usesmallheader'):false
            },
            buttons: [{
                type: 'submit',
                text: 'Insert'
            },{
                type: 'cancel',
                text: 'Cancel'
            }],
            onChange: (api,details) => {
                // store current value in properties
                let name = details.name;
                let data = api.getData();
                let value = data[name];
                this.properties.Set(name,value);
            },
            onSubmit: (api)=>{
                this.PluginSubmit(editor);
                api.close();
            }
        };
        // issue call to verify availability of facebook app configuration
        // if successful, set widget as enabled
        // call is a side effect to the main call
        // main call must finish in sync
        let data = new FormData();
        data.append('integration','facebook_application');
        let promise = this._context.Controller.SystemHasIntegration(data).then((data)=>{
            if (data.available === true)
            {
                // set plugin as enabled (in robj)
                robj.disabled = false;
            }
        });
        /**
         * Plugin interaction
         * Only modern browsers
         * @returns {undefined}
         */
        let fhclick = ()=>{
            editor = tinymce.activeEditor;
            // open window
            editor.windowManager.open(dConfiguration);
        };
        /**
         * Return object
         * @type object
         */
        robj = {
            text: 'Facebook page',
            icon: 'facebook',
            disabled: true,
            onAction: fhclick,
            resolved: promise
        };
        return robj;
    }
}

/**
 * Javascript Facebook post (oembed) widget class
 * 
 * Handling client side of interaction
 */

class FacebookPost extends Widget
{
    /**
     * View constructor
     * @param {object} context 
     * @param {element} el 
     * @returns {object instance}
     */
    constructor(context,el)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Core\\View\\Widgets\\FacebookPost",
            type: 'static'
        };
        // set activation callback
        this.activation.callback = (response,resolve) => {
            // call all scripts in response
            let scripts = response.element.querySelectorAll('script');
            scripts.forEach((script)=>{
                // create new script element
                // cloning does not seem to work, do it the long way
                let el = document.createElement('script');
                // transfer only the source attribute
                el.src = script.src;
                // insert script element in document
                document.documentElement.appendChild(el);
                // remove script
                script.remove();
            });
            resolve(response);
        };
        return this;
    }
    /**
     * Method for getting the plugin object
     * The plugin object is prepared for use in TinyMCE
     * Only modern browsers
     * @returns {object}
     */
    GetPluginObject()
    {
        // return object
        let robj;
        // editor object
        let editor;
        /**
         * Dialog configuration object
         * Get initial data from properties list, ensure string format
         */
        let dConfiguration = {
            title: 'Facebook post',
            body: {
                type: 'panel',
                items: [{
                    type: 'label',
                    label: 'This widget inserts an embedded Facebook post at the caret position',
                    items: []
                },{
                    type: 'input',
                    name: 'url',
                    label: 'Page url'
                }]
            },
            initialData: {
                url: this.properties.IsSet('url')?this.properties.Get('url'):''
            },
            buttons: [{
                type: 'submit',
                text: 'Insert'
            },{
                type: 'cancel',
                text: 'Cancel'
            }],
            onChange: (api,details) => {
                // store current value in properties
                let name = details.name;
                let data = api.getData();
                let value = data[name];
                this.properties.Set(name,value);
            },
            onSubmit: (api)=>{
                this.PluginSubmit(editor);
                api.close();
            }
        };
        // issue call to verify availability of facebook app configuration
        // if successful, set widget as enabled
        // call is a side effect to the main call
        // main call must finish in sync
        let data = new FormData();
        data.append('integration','facebook_application');
        let promise = this._context.Controller.SystemHasIntegration(data).then((data)=>{
            if (data.available === true)
            {
                // set plugin as enabled (in robj)
                robj.disabled = false;
            }
        });
        /**
         * Plugin interaction
         * Only modern browsers
         * @returns {undefined}
         */
        let fhclick = ()=>{
            editor = tinymce.activeEditor;
            // open window
            editor.windowManager.open(dConfiguration);
        };
        /**
         * Return object
         * @type object
         */
        robj = {
            text: 'Facebook post',
            icon: 'facebook',
            disabled: true,
            onAction: fhclick,
            resolved: promise
        };
        return robj;
    }
}

/**
 * Javascript Facebook video (oembed) widget class
 * 
 * Handling client side of interaction
 */

 class FacebookVideo extends Widget
 {
    /**
     * View constructor
     * @param {object} context 
     * @param {element} el 
     * @returns {object instance}
     */
    constructor(context,el)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Core\\View\\Widgets\\FacebookVideo",
            type: 'static'
        };
        // set activation callback
        this.activation.callback = (response,resolve) => {
            // call all scripts in response
            let scripts = response.element.querySelectorAll('script');
            scripts.forEach((script)=>{
                // create new script element
                // cloning does not seem to work, do it the long way
                let el = document.createElement('script');
                // transfer only the source attribute
                el.src = script.src;
                // insert script element in document
                document.documentElement.appendChild(el);
                // remove script
                script.remove();
            });
            resolve(response);
        };
        return this;
    }
    /**
     * Method for getting the plugin object
     * The plugin object is prepared for use in TinyMCE
     * Only modern browsers
     * @returns {object}
     */
    GetPluginObject()
    {
        // return object
        let robj;
        // editor object
        let editor;
        /**
         * Dialog configuration object
         * Get initial data from properties list, ensure string format
         */
        let dConfiguration = {
            title: 'Facebook video',
            body: {
                type: 'panel',
                items: [{
                    type: 'label',
                    label: 'This widget inserts an embedded Facebook video at the caret position',
                    items: []
                },{
                    type: 'input',
                    name: 'url',
                    label: 'Page url'
                }]
            },
            initialData: {
                url: this.properties.IsSet('url')?this.properties.Get('url'):''
            },
            buttons: [{
                type: 'submit',
                text: 'Insert'
            },{
                type: 'cancel',
                text: 'Cancel'
            }],
            onChange: (api,details) => {
                // store current value in properties
                let name = details.name;
                let data = api.getData();
                let value = data[name];
                this.properties.Set(name,value);
            },
            onSubmit: (api)=>{
                this.PluginSubmit(editor);
                api.close();
            }
        };
        // issue call to verify availability of facebook app configuration
        // if successful, set widget as enabled
        // call is a side effect to the main call
        // main call must finish in sync
        let data = new FormData();
        data.append('integration','facebook_application');
        let promise = this._context.Controller.SystemHasIntegration(data).then((data)=>{
            if (data.available === true)
            {
                // set plugin as enabled (in robj)
                robj.disabled = false;
            }
        });
        /**
         * Plugin interaction
         * Only modern browsers
         * @returns {undefined}
         */
        let fhclick = ()=>{
            editor = tinymce.activeEditor;
            // open window
            editor.windowManager.open(dConfiguration);
        };
        /**
         * Return object
         * @type object
         */
        robj = {
            text: 'Facebook video',
            icon: 'facebook',
            disabled: true,
            onAction: fhclick,
            resolved: promise
        };
        return robj;
    }
 }

/**
 * Javascript Instagram post (oembed) widget class
 * 
 * Handling client side of interaction
 */

class InstagramPost extends Widget
{
    /**
     * View constructor
     * @param {object} context 
     * @param {element} el 
     * @returns {object instance}
     */
    constructor(context,el)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Core\\View\\Widgets\\InstagramPost",
            type: 'static'
        };
        // set activation callback
        this.activation.callback = (response,resolve) => {
            // call all scripts in response
            let scripts = response.element.querySelectorAll('script');
            scripts.forEach((script)=>{
                // create new script element
                // cloning does not seem to work, do it the long way
                let el = document.createElement('script');
                // transfer only the source attribute
                el.src = script.src;
                // insert script element in document
                document.documentElement.appendChild(el);
                // remove script
                script.remove();
            });
            resolve(response);
        };
       return this;
    }
    /**
     * Method for getting the plugin object
     * The plugin object is prepared for use in TinyMCE
     * Only modern browsers
     * @returns {object}
     */
    GetPluginObject()
    {
        // return object
        let robj;
        // editor object
        let editor;
        /**
         * Dialog configuration object
         * Get initial data from properties list, ensure string format
         */
        let dConfiguration = {
            title: 'Instagram post',
            body: {
                type: 'panel',
                items: [{
                    type: 'label',
                    label: 'This widget inserts an embedded Instagram post at the caret position',
                    items: []
                },{
                    type: 'input',
                    name: 'url',
                    label: 'Post url'
                },{
                    type: 'checkbox',
                    name: 'hidecaption',
                    label: 'Hide caption'
                }]
            },
            initialData: {
                url: this.properties.IsSet('url')?this.properties.Get('url'):'',
                hidecaption: this.properties.IsSet('hidecaption')?this.properties.Get('hidecaption'):false
            },
            buttons: [{
                type: 'submit',
                text: 'Insert'
            },{
                type: 'cancel',
                text: 'Cancel'
            }],
            onChange: (api,details) => {
                // store current value in properties
                let name = details.name;
                let data = api.getData();
                let value = data[name];
                this.properties.Set(name,value);
            },
            onSubmit: (api)=>{
                this.PluginSubmit(editor);
                api.close();
            }
        };
        // issue call to verify availability of instagram app configuration
        // if successful, set widget as enabled
        // call is a side effect to the main call
        // main call must finish in sync
        let data = new FormData();
        data.append('integration','instagram_application');
        let promise = this._context.Controller.SystemHasIntegration(data).then((data)=>{
            if (data.available === true)
            {
                // set plugin as enabled (in robj)
                robj.disabled = false;
            }
        });
        /**
         * Plugin interaction
         * Only modern browsers
         * @returns {undefined}
         */
        let fhclick = ()=>{
            editor = tinymce.activeEditor;
            // open window
            editor.windowManager.open(dConfiguration);
        };
        /**
         * Return object
         * @type object
         */
        robj = {
            text: 'Instagram post',
            icon: 'instagram',
            disabled: true,
            onAction: fhclick,
            resolved: promise
        };
        return robj;
    }
}

/**
 * Javascript calendar events element block class
 * 
 * Handling client side of interaction
 */

class CalendarEvents extends Block
{
    /**
     * CalendarEvents constructor
     * @param {object} context 
    * @returns {object instance}  
     */
    constructor(context)
    {
       super(... arguments);
       // invokable data
       this._idata = {
           class: "Core\\View\\Blocks\\CalendarEvents",
           type: 'static'
       };
       return this;
    }
}

/**
 * Javascript storage files element block class
 * 
 * Handling client side of interaction
 */

class StorageFiles extends Block
{
    /**
     * StorageFiles constructor
     * @param {object} context 
    * @returns {object instance}  
     */
    constructor(context)
    {
       super(... arguments);
       // invokable data
       this._idata = {
           class: "Core\\View\\Blocks\\StorageFiles",
           type: 'static'
       };
       return this;
    }
}

/**
 * Javascript navigation element block class
 * 
 * Handling client side of interaction
 */

class Navigation extends Block
{
    /**
     * Navigation constructor
     * @param {object} context 
     * @returns {object instance}  
     */
    constructor(context)
    {
       super(... arguments);
       // invokable data
       this._idata = {
           class: "Core\\View\\Blocks\\Navigation",
           type: 'static'
       };
       return this;
    }
}

/**
 * Javascript view class
 * Represents the document, handles all interaction with the user
 * 
 * Called methods return a promise for async handling
 * Handling client side of interaction
 */

class View$1 extends Invokable
{
    /**
     * View constructor
     * @param {object} context
     * @returns {object instance}
     */
    constructor(context)
    {
        super();
        this._context = context;
        // invokable data
        this._idata = {
            class: "Core\\View",
            type: 'serializable'
        };
        // available components
        // pass context to constructor
        this.components = {
            Lightbox: new Lightbox(context),
            Validator: new Validator(context),
            Logger: new Logger(context),
            AccessManager: new AccessManager(context),
            Navigator: new Navigator(context)
        };
        // widget factory
        let obj = this;
        this.widgetfactory = {
            widgets: {
                SingleImage: SingleImage,
                AlbumGallery: AlbumGallery,
                CalendarExtract: CalendarExtract,
                CloudStorageFolder: CloudStorageFolder,
                NewsArticles: NewsArticles,
                Form: Form,
                FacebookPage: FacebookPage,
                FacebookPost: FacebookPost,
                FacebookVideo: FacebookVideo,
                InstagramPost: InstagramPost
            },
            /**
             * Method for creating a single widget
             * @param {string} cname
             * @param {array} args
             * @returns {object}
             */
            Create(cname, ...args) {
                let cls = this.widgets[cname];
                return new cls(obj._context, ...args);
            },
            /**
             * Method for creating instances of all available widgets
             * @returns {array of objects}
             */
            CreateAll() {
                let rval = [];
                for (let prop in this.widgets)
                {
                    rval.push(this.Create(prop));
                }
                return rval;
            }
        };
        // block factory
        this.blockfactory = {
            blocks: {
                CalendarEvents: CalendarEvents,
                StorageFiles: StorageFiles,
                Navigation: Navigation
            },
            /**
             * Method for creating a single block
             * @param {string} cname
             * @param {array} args
             * @returns {object}
             */
            Create(cname, ...args) {
                let cls = this.blocks[cname];
                return new cls(obj._context, ...args);
            }
        };
        // add listener for DOMContentLoaded
        // needs to be called through event listener, DOM elements are needed
        document.addEventListener('DOMContentLoaded',()=>
        {
            this.ParseWidgetsInParent(document);
        });
        return this;
    }

    /**
     * Method for parsing all widget (condensed) elements in a parent
     * @param {parent element} parent 
     * @returns {promise}
     */
    ParseWidgetsInParent(parent)
    {
        // parse available widgets
        // use Promise.all
        // get parsed and condensed widgets
        let wpromises = [];
        let wel = parent.querySelectorAll('.ct-widget-parsed,.ct-widget-condensed');
        for (let wi = 0; wi < wel.length; wi++)
        {
            wpromises.push(this.ParseWidget(wel[wi],wi));
        }
        return Promise.all(wpromises);
    }
    
    /**
     * Method for parsing a single widget element
     * @param {widget element} el
     * @param {int} order 
     * @returns {promise}
     */
    ParseWidget(el,order)
    {
        // create object
        let cattr = el.getAttribute('data-class');
        let cobj = this.widgetfactory.Create(cattr,el);
        // set property customization filter
        cobj.properties.styles.filter = `ct-widget-condensed[data-class="${cattr}"]`;
        // determine parsed status
        let parsed = el.classList.contains('ct-widget-parsed');
        // parse or finalize widget object
        return new Promise((resolve,reject) =>
        {
            // considered the cobj activation state here
            let fh_resolve;
            if (parsed)
            {
                fh_resolve = ()=>{
                    if (cobj.activation.callback !== null)
                    {
                        let response = {
                            element: el
                        };
                        cobj.activation.callback(response,resolve,reject,order);
                    }
                    else
                    {
                        resolve();
                    }
                };
            }
            else
            {
                // set loading class for element
                // loading class is automatically removed if child is replaced
                el.classList.add('ct-loading');
                fh_resolve = ()=>{
                    cobj.GetElement(el,order).then((data)=>{
                        el.parentNode.replaceChild(data.element,el);
                        resolve();
                    }).catch((error)=>{
                        el.classList.remove('ct-loading');
                        reject(error);
                    });
                };
            }
            switch (cobj.activation.state)
            {
                case 'loading':
                case 'interactive':
                    // call fh_resolve immediately
                    fh_resolve();
                    break;
                case 'complete':
                    // embed fh_resolve in event listener (if not already passed)
                    if (document.readyState === 'complete')
                    {
                        fh_resolve();
                    }
                    else
                    {
                        window.addEventListener('load',fh_resolve);
                    }
                    break;
            }
        });
    }
    
    /**
     * Method for informing the user of an error
     * Wrapper for logger component method
     * @param {string} message
     * @returns {promise}
     */
    Error(message)
    {
        // issue call to logger component
        return this.components.Logger.Error(message);
    }
    
    /**
     * Method for asking the user for confirmation
     * Wrapper for logger component method
     * @param {string} message
     * @returns {promise}
     */
    Confirm(message)
    {
        // issue call to logger component
        return this.components.Logger.Confirm(message);
    }
    
    /**
     * Method for informing the user
     * Wrapper for logger component method
     * @param {string} message
     * @returns {promise}
     */
    Inform(message)
    {
        // issue call to logger component
        return this.components.Logger.Inform(message);
    }
    /**
     * Method for halting a promise chain for a set duration
     * @param {int} duration
     * @returns {promise}
     */
    Wait(duration)
    {
        return new Promise(function(resolve){
            window.setTimeout(function(){
                resolve();
            },duration);
        });
    }
    /**
     * Method for shaking the supplied element
     * @param {element} el
     * @returns {promise}
     */
    Shake(el)
    {
        el.addEventListener("animationend", ()=>{
            el.classList.remove('ct-shake');
        });
        el.classList.add('ct-shake');
        return this.Wait(820);
    }
    /**
     * Method for copying a value to the clipboard
     * Works via execCommand
     * @param {string} value 
     * @returns {promise}
     */
    CopyToClipboard(value)
    {
        // to ensure compatibility, use execCommand
        // a dummy element is needed for copy command
        var textArea = document.createElement('textarea');
        // apply styling
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        // set value in textarea
        textArea.value = value;
        // add element and focus it
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        // return promise
        return new Promise((resolve)=>{
            document.execCommand('copy');
            resolve();
        }).finally(()=>{
            // remove element
            document.body.removeChild(textArea);
        });
    }
}

/**
 * Javascript instagram news boxes widget class
 * 
 * Handling client side of interaction
 */

 class InstagramNewsBoxes extends Widget
 {
    /**
     * Widget constructor
     * @param {object} context 
     * @param {element} el 
     * @returns {object instance}
     */
    constructor(context,el)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Ingenjorslosningar\\View\\Widgets\\InstagramNewsBoxes",
            type: 'static'
        };
        this._element = null;
        // maintain a component list
        this.components = {
            container: {
                el: null,
                /**
                 * Method for getting the current divisor value
                 * @returns int
                 */
                GetDivisor: ()=>{
                    // get current divisor value from css value defined on container
                    let divisor = parseInt(getComputedStyle(this.components.container.el).getPropertyValue('--grid-divisor'));
                    return divisor;
                }
            },
            details: {
                el: null,
                /**
                 * Method for hiding the details
                 */
                Hide: ()=>{
                    this.components.details.el.style.display = 'none';
                },
                /**
                 * Method for showing the details
                 */
                Show: ()=>{
                    this.components.details.el.style.display = 'initial';
                },
                /**
                 * Method for setting the details order
                 * @param {int} order 
                 */
                SetOrder: (order)=>{
                    this.components.details.el.style.order = order;
                },
                /**
                 * Method for setting the details contents
                 * @param {string} contents 
                 */
                SetContents: (contents)=>{
                    this.components.details.el.innerHTML = contents;
                }
            },
            posts: {
                el: null,
                selected: null,
                /**
                 * Method for post click event
                 * @param {element} el 
                 */
                Click: (el)=>{
                    // set container as loading
                    this.components.container.el.classList.add('ct-loading');
                    // check against selected
                    if (el === this.components.posts.selected)
                    {
                        // hide details element
                        this.components.details.Hide();
                        // remove selection
                        this.components.posts.selected = null;
                        // remove loading status from container
                        this.components.container.el.classList.remove('ct-loading');
                        return;
                    }
                    // get current divisor value from css value defined on container
                    let divisor = this.components.container.GetDivisor();
                    // get count from clicked element
                    let count = parseInt(el.dataset.count);
                    // calculate order for details element
                    let order = Math.ceil((count+1)/(2*divisor))*2*divisor-1;
                    // set order for details element
                    this.components.details.SetOrder(order);
                    // transfer element contents to details element
                    let contents = el.innerHTML;
                    this.components.details.SetContents(contents);
                    // show details element
                    this.components.details.Show();
                    // store this element as selected
                    this.components.posts.selected = el;
                    // remove loading status from container
                    this.components.container.el.classList.remove('ct-loading');
                }
            }
        };
        // set activation callback
        this.activation.callback = (response,resolve,reject) => {
            // store element
            this._element = response.element;
            // map elements
            this.components.container.el = response.element.querySelector("section[data-purpose='instagram-box-container']");
            this.components.details.el = response.element.querySelector("[data-purpose='instagram-post-details']");
            this.components.posts.el = response.element.querySelectorAll("[data-purpose='instagram-post-container']");
            // set event listeners
            this.components.posts.el.forEach((el)=>{
                // set click event
                el.addEventListener('click',()=>{this.components.posts.Click(el);});
            });
            resolve(response);
        };
        return this;
    }

    /**
     * Method for getting the Instagram news providers
     * @returns {Promise}
     */
    GetInstagramNewsProviders()
    {
        // issue call to server
        // send blank FormData object
        let data = new FormData();
        // append method name
        data.append("method","GetInstagramNewsProviders");
        return this.invoke(data);
    }

    /**
     * Method for getting the plugin object
     * The plugin object is prepared for use in TinyMCE
     * Only modern browsers
     * @returns {object}
     */
    GetPluginObject()
    {
        // return object
        let robj;
        // editor object
        let editor;
        // create provider selectbox base
        let aprovidersselectboxvalues = [];
        /**
         * Dialog configuration object
         */
        let dConfiguration = {
            title: 'Instagram news boxes',
            body: {
                type: 'panel',
                items: [{
                    type: 'label',
                    label: 'This widget inserts Instagram news boxes at the caret position',
                    items: []
                },{
                    type: 'selectbox',
                    name: 'providerkey',
                    label: 'Select news provider',
                    items: aprovidersselectboxvalues
                },{
                    type: 'input',
                    name: 'articlecount',
                    label: 'Article count'
                },{
                    type: 'input',
                    name: 'articleoffset',
                    label: 'Article offset'
                }]
            },
            initialData: {
                articlecount : this.properties.IsSet('articlecount')?`${this.properties.Get('articlecount')}`:'3',
                articleoffset: this.properties.IsSet('articleoffset')?`${this.properties.Get('articleoffset')}`:'0'
            },
            buttons: [{
                type: 'submit',
                text: 'Insert'
            },{
                type: 'cancel',
                text: 'Cancel'
            }],
            onChange: (api,details) => {
                // store current value in properties
                let name = details.name;
                let data = api.getData();
                let value = data[name];
                this.properties.Set(name,value);
            },
            onSubmit: (api)=>{
                this.PluginSubmit(editor);
                api.close();
            }
        };
        // issue call to verify existance of module
        // if successful, set widget as enabled
        // call is a side effect to the main call
        // main call must finish in sync
        let aproviders, data = new FormData();
        data.append('module','Core\\Modules\\News');
        let promise = this._context.Controller.SystemHasModule(data).then((data)=>{
            if (data.available === true)
            {
                return this.GetInstagramNewsProviders().then((data)=>{
                    aproviders = data['providers'];
                    // append values to providers selectbox
                    aproviders.forEach((item)=>{
                        aprovidersselectboxvalues.push({
                            text: `${item['pK']}: ${item['Name']}`,
                            value: item['pK']
                        });
                        // check if key matches property value (if set)
                        if (this.properties.IsSet('providerkey') && this.properties.Get('providerkey') == item['pK'])
                        {
                            dConfiguration.initialData['providerkey'] = item['pK'];
                        }
                    });
                    if (aproviders.length > 0)
                    {
                        // store initial values in object properties, if not set
                        if (!this.properties.IsSet('providerkey'))
                        {
                            this.properties.Set('providerkey',aproviders[0].pK);
                        }
                        // set plugin as enabled (in robj)
                        robj.disabled = false;
                    }
                }).catch((error)=>{
                    console.log(error);
                });
            }
        });
        /*
         * Plugin interaction
         * @returns {undefined}
         */
        let fhclick = ()=>{
            editor = tinymce.activeEditor;
            // open window
            editor.windowManager.open(dConfiguration);
        };
        /*
         * Return object
         * @type object
         */
        robj = {
            text: 'Instagram news boxes',
            icon: 'instagram',
            disabled: true,
            onAction: fhclick,
            resolved: promise
        };
        return robj;
    }
 }

/*
 * ingenjorslosningar javascript view class
 * Represents the document, handles all interaction with the user
 * 
 * Called methods return a promise for async handling
 * Handling client side of interaction
 */

class View extends View$1
{
    /*
     * View constructor
     * @param {object} context
     * @returns {object instance}
     */
    constructor(context)
    {
        super(... arguments);
        // invokable data
        this._idata = {
            class: "Ingenjorslosningar\\View",
            type: 'serializable'
        };
        // add administration components
        this.widgetfactory.widgets.InstagramNewsBoxes = InstagramNewsBoxes;
        // stored object data
        this.elements = {
            navigation: {
                container: {
                    el: null,
                    height: null
                },
                breakpoint: {
                    el: null,
                    position: null
                }
            }
        };
        // header box data
        this.boxes = {
            cell: {
                list: [],
                /**
                 * Method for setting cell dimensions
                 * @param {element} cell 
                 */
                SetDimensions: (cell)=>{
                    cell.style.setProperty('--cell-width',cell.clientWidth);
                    cell.style.setProperty('--cell-height',cell.clientHeight);
                },
                /**
                 * Method for setting cell animation value
                 * @param {element} cell 
                 */
                SetAnimationValue: (cell)=>{
                    cell.style.setProperty('--anim-val',Math.floor(Math.random() * 20)-10);
                }
            },
            /**
             * Method for boxes initialization, transition from 2d to 3d
             */
            Initialize: ()=>{
                // populate cell list
                this.boxes.cell.list = document.querySelectorAll("[data-grid-area]");
                // set cell dimensions
                this.boxes.cell.list.forEach((cell)=>{
                    this.boxes.cell.SetDimensions(cell);
                });
                // start animations
                this.boxes.cell.list.forEach((cell)=>{
                    // get original duration and cell color
                    let duration = getComputedStyle(cell).getPropertyValue('transition-duration');
                    let durationValue = parseFloat(duration)*1000;
                    let animationhandle = null;
                    let fh = ()=>{
                        cell.style.removeProperty('transition-duration');
                        this.boxes.cell.SetAnimationValue(cell);
                        animationhandle = window.setTimeout(fh, durationValue);
                    };
                    if (cell.dataset.animationTile !== undefined)
                    {
                        // animation loop
                        fh();
                        // click action for top face
                        let face = cell.querySelector("[data-grid-face='top']");
                        face.addEventListener('click',()=>{
                            window.clearTimeout(animationhandle);
                            cell.style.setProperty('transition-duration','0.1s');
                            cell.style.setProperty('--anim-val',-100);
                            animationhandle = window.setTimeout(fh, durationValue);
                        });
                    }
                });
            },
            /**
             * Method for boxes refresh
             */
            Refresh: ()=>{
                // set cell dimensions
                this.boxes.cell.list.forEach((cell)=>{
                    this.boxes.cell.SetDimensions(cell);
                });
            }
        };
        // add listener for DOMContentLoaded
        document.addEventListener('DOMContentLoaded',()=>
        {
            // add listener in navigator component
            this.components.Navigator.addEventListener('ToggleNavigation',()=>{
                // refresh header boxes
                this.boxes.Refresh();
            });
            // initialize header boxes
            this.boxes.Initialize();
        });
        // add listener for window resize (passive, to hinder throttling)
        // use timer and handle to further reduce load
        let resizehandle = null;
        window.addEventListener('resize',()=>
        {
            let fh = ()=>{
                // refresh header boxes
                this.boxes.Refresh();
            };
            if (resizehandle !== null)
            {
                window.clearTimeout(resizehandle);
            }
            resizehandle = window.setTimeout(fh,500);
        },{capture: false, passive: true});
        return this;
    }
}

/*
 * Javascript controller class
 * Responsible for all interaction between the view (JS) and the model (PHP)
 * The JS controller class has a PHP (static) counterpart for the server calls
 * 
 * Called methods return a promise for async handling
 * 
 * Methods in this parent class are available to all child classes
 */
class Controller$1 extends Invokable
{
    /**
     * Controller constructor
     * @returns {Controller instance}
     */
    constructor()
    {
        super();
        // invokable data
        this._idata = {
            class: "Core\\Controller",
            type: 'static'
        };
        return this;
    }
    /**
     * Method for System flush
     * @returns {promise}
     */
    SystemFlush()
    {
        // issue call to server
        // send blank FormData object
        let data = new FormData();
        // append method name
        data.append("method","SystemFlush");
        return this.invoke(data);
    }
    /**
     * Method for Accounts module get vendor count
     * @returns {promise}
     */
    AccountsGetVendorCount()
    {
        // issue call to server
        // send blank FormData object
        let data = new FormData();
        // append method name
        data.append("method","AccountsGetVendorCount");
        return this.invoke(data);
    }
    /**
     * Method for Sections get sections
     * @returns {promise}
     */
    SectionsGetSections()
    {
        // issue call to server
        // send blank FormData object
        let data = new FormData();
        // append method name
        data.append("method","SectionsGetSections");
        return this.invoke(data);
    }
    /**
     * Method for News module get provider
     * @param {FormData} data
     * @returns {promise}
     */
    NewsGetProvider(data)
    {
        // append method name to supplied FormData data
        data.append("method","NewsGetProvider");
        return this.invoke(data);
    }
    /**
     * Method for News module get providers
     * @returns {promise}
     */
     NewsGetProviders()
    {
        // issue call to server
        // send blank FormData object
        let data = new FormData();
        // append method name
        data.append("method","NewsGetProviders");
        return this.invoke(data);
    }
    /**
     * Method for Languages module retrieval of current language
     * @param {FormData} data
     * @returns {promise}
     */
    LanguagesGetCurrentLanguage(data)
    {
        // append method name to supplied FormData data
        data.append("method","LanguagesGetCurrentLanguage");
        return this.invoke(data);
    }
    /**
     * Method for Languages module setting current language
     * @param {FormData} data 
     * @returns {promise}
     */
    LanguagesSetCurrentLanguage(data)
    {
        // append method name to supplied FormData data
        data.append("method","LanguagesSetCurrentLanguage");
        return this.invoke(data);
    }
    /**
     * Method for Languages module setting localization
     * @param {FormData} data
     * @returns {promise}
     */
    LanguagesSetLocalization(data)
    {
        // append method name to supplied FormData data
        data.append("method","LanguagesSetLocalization");
        return this.invoke(data);
    }
    /**
     * Method for Images module get albums
     * @returns {promise}
     */
    ImagesGetAlbums()
    {
        // issue call to server
        // send blank FormData object
        let data = new FormData();
        // append method name
        data.append("method","ImagesGetAlbums");
        return this.invoke(data);
    }
    /**
     * Method for Calendars module get calendar
     * @param {FormData} data
     * @returns {promise}
     */
    CalendarsGetCalendar(data)
    {
        // append method name to supplied FormData data
        data.append("method","CalendarsGetCalendar");
        return this.invoke(data);
    }
    /**
     * Method for Calendars module get calendars
     * @returns {promise}
     */
    CalendarsGetCalendars()
    {
        // issue call to server
        // send blank FormData object
        let data = new FormData();
        // append method name
        data.append("method","CalendarsGetCalendars");
        return this.invoke(data);
    }
    /**
     * Method for Storages module get storages
     * @returns {promise}
     */
    StoragesGetStorages()
    {
        // issue call to server
        // send blank FormData object
        let data = new FormData();
        // append method name
        data.append("method","StoragesGetStorages");
        return this.invoke(data);
    }
    /**
     * Method for Storages module get storage
     * @param {FormData} data
     * @returns {promise}
     */
    StoragesGetStorage(data)
    {
        // append method name to supplied FormData data
        data.append("method","StoragesGetStorage");
        return this.invoke(data);
    }
    /**
     * Method for Forms module add response
     * @param {element} form 
     * @returns {promise}
     */
    FormsAddResponse(form)
    {
        // issue call to server
        // send form as FormData object
        let data = new FormData(form);
        // append method name to supplied FormData data
        data.append("method","FormsAddResponse");
        return this.invoke(data);
    }
    /**
     * Method for Forms module get form by tag
     * @param {FormData} data 
     * @returns {promise}
     */
    FormsGetFormByTag(data)
    {
        // append method name to supplied FormData data
        data.append("method","FormsGetFormByTag");
        return this.invoke(data);
    }
    /**
     * Method for Forms module get forms by language
     * @param {FormData} data 
     */
    FormsGetFormsByLanguage(data)
    {
        // append method name to supplied FormData data
        data.append("method","FormsGetFormsByLanguage");
        return this.invoke(data);
    }
}

/*
 * ingenjorslosningar Javascript controller class
 * Responsible for all interaction between the view (JS) and the model (PHP)
 * The JS controller class has a PHP (static) counterpart for the server calls
 * 
 * Called methods return a promise for async handling
 */
class Controller extends Controller$1
{
    /*
     * Controller constructor
     * @returns {Controller instance}
     */
    constructor()
    {
        super();
        // invokable data
        this._idata = {
            class: "Ingenjorslosningar\\Controller",
            type: 'static'
        };
        return this;
    }
    /**
     * Method for BoxGame module get records
     * @returns {promise}
     */
    BoxGameGetRecords()
    {
        // issue call to server
        // send blank FormData object
        let data = new FormData();
        // append method name
        data.append("method","BoxGameGetRecords");
        return this.invoke(data);
    }
    /**
     * Method for BoxGame module get records
     * @returns {promise}
     */
    BoxGameGetHighScore()
    {
        // issue call to server
        // send blank FormData object
        let data = new FormData();
        // append method name
        data.append("method","BoxGameGetHighScore");
        return this.invoke(data);
    }
    /**
     * Method for BoxGame module add record
     * @param {FormData} data
     * @returns {promise}
     */
    BoxGameAddRecord(data)
    {
        // append method name to supplied FormData data
        data.append("method","BoxGameAddRecord");
        return this.invoke(data);
    }
}

/*
 * 
 * ingenjorslosningar javascript
 * 
 * dependent on
 * - [none]
 */

// Declare main module
var ingenjorslosningar = {};

/*
 * Main module
 */
(function(context){
    context.View = new View(context);
    context.Controller = new Controller();
})(ingenjorslosningar);
